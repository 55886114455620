import axios from "axios";
import { api } from "src/utils/api";
import user from "src/utils/userDetails";

export const getRestaurantInfo = async (url) => {
  let res = await axios.get(`${api}/restaurant/info-carta/${url}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getCategoryInfo = async (idRestaurant) => {
  let res = await axios.get(`${api}/category/${idRestaurant}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getSubCategoryInfo = async (idCategory) => {
  let res = await axios.get(`${api}/category/subcategories/${idCategory}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getDishesInfo = async (idSubcategory) => {
  let res = await axios.get(`${api}/subcategory/dishes/${idSubcategory}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
