import axios from "axios";
import { api } from "src/utils/api";
import user from "src/utils/userDetails";

export const importDishes = async (type, file) => {
  let res = await axios.post(
    `${api}/dish/import/${user.restaurant._id}/${type}`,
    file,
    { headers: { Authorization: user.token } }
  );
  return res.data;
};

export const importIngredients = async (type, file) => {
  let res = await axios.post(
    `${api}/ingredient/import/${user.restaurant._id}/${type}`,
    file,
    { headers: { Authorization: user.token } }
  );
  return res.data;
};

export const importLocation = async (file) => {
  let res = await axios.post(
    `${api}/restaurant/import/locations/${user.restaurant._id}`,
    file,
    { headers: { Authorization: user.token } }
  );
  return res.data;
};

export const importClients = async (file) => {
  let res = await axios.post(
    `${api}/customer/import/${user.restaurant._id}`,
    file,
    { headers: { Authorization: user.token } }
  );
  return res.data;
};
