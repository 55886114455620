import user from "src/utils/userDetails";

const formatMoney = (value, currency = user?.restaurant?.currencyVisual ?? "S/.") => {
  /*   let f = new Intl.NumberFormat("es-ES", {
    style: "currency",
    currency: "PEN",
    minimumFractionDigits: 2,
  }); */
  let f = new Intl.NumberFormat("es-PE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
  return `${currency} ${f}`;
  //return f.format(value);
};
export default formatMoney;
