import axios from "axios";

const token = "4c2f3c4f1c834b8202c01b7cb0fc9612888ce61a";
// CON ESTA PETICION OBTINEN EL GUID (PASAR TOKEN DE PARAMETRO)
// https://api-ssl.bitly.com/v4/groups

export const generateShortLink = async (data) => {
  let res = await axios.post(
    `https://api-ssl.bitly.com/v4/shorten`,
    { long_url: data, domain: "bit.ly" },
    {
      headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
    }
  );
  return res.data;
};
