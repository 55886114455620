// assets
import {
  IconSoup,
  IconListCheck,
  IconSitemap,
  IconSmartHome,
  IconBuildingBank,
  IconChartArrowsVertical,
  IconFriends,
  IconBorderAll,
  IconUsers,
  IconUser,
  IconAffiliate,
  IconClipboardList,
  IconToolsKitchen2,
  IconMeat,
  IconBoxMultiple,
  IconBoxModel,
  IconGlassFull,
  IconUpload,
  IconPrinter,
  IconReportAnalytics,
  IconBottle,
  IconReceipt,
  IconCoin,
  IconCalendarEvent,
  IconReceiptTax,
  IconDiscount2,
  IconCash,
  IconMotorbike,
  IconAd,
  IconToolsKitchen,
  IconHandStop,
  IconSlice,
} from "@tabler/icons";
import InboxIcon from "@material-ui/icons/Inbox";
import SettingsIcon from "@material-ui/icons/Settings";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import CategoryIcon from "@material-ui/icons/Category";
import ChangeHistoryIcon from "@material-ui/icons/ChangeHistory";
import PrintIcon from "@material-ui/icons/Print";
import PaymentIcon from "@material-ui/icons/Payment";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import ReportIcon from "@material-ui/icons/Report";

//-----------------------|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||-----------------------//
const admin = {
  id: "menu",
  type: "group",
  title: "ADMINISTRADOR",
  children: [
    {
      id: "inicio",
      title: "INICIO",
      type: "item",
      url: "/inicio",
      icon: IconSmartHome,
      breadcrumbs: false,
    },
    {
      id: "cuadreCaja",
      title: "CUADRE DE CAJA",
      type: "item",
      icon: IconCoin,
      url: "/cuadre-caja",
      breadcrumbs: false,
    },
    {
      id: "cashier",
      title: "CAJA",
      type: "item",
      icon: IconBuildingBank,
      url: "/caja",
      breadcrumbs: false,
    },
    // {
    //   id: "freetables",
    //   title: "MESAS DISPONIBLES",
    //   type: "item",
    //   icon: IconBorderAll,
    //   url: "/freetables",
    //   breadcrumbs: false,
    // },
    // {
    //   id: "orders",
    //   title: "PEDIDOS",
    //   type: "item",
    //   icon: IconListCheck,
    //   url: "/pedidos",
    //   breadcrumbs: false,
    // },
    // {
    //   id: "ordersList",
    //   title: "LISTA DE PEDIDOS",
    //   type: "item",
    //   icon: IconClipboardList,
    //   url: "/lista/pedidos",
    //   breadcrumbs: false,
    // },
    // {
    //   id: "kitchen",
    //   title: "COCINA",
    //   type: "item",
    //   icon: IconSlice,
    //   url: "/lista/cocina",
    //   breadcrumbs: false,
    // },
    // {
    //   id: "waitList",
    //   title: "LISTA DE ESPERA",
    //   type: "item",
    //   icon: IconHandStop,
    //   url: "/lista-espera",
    //   breadcrumbs: false,
    // },
    // {
    //   id: "deliveryOrders",
    //   title: "DELIVERISTA",
    //   type: "item",
    //   icon: IconMotorbike,
    //   url: "/deliverista",
    //   breadcrumbs: false,
    // },
    // {
    //   id: "deliveryOrdersList",
    //   title: "PEDIDOS DELIVERY",
    //   type: "item",
    //   icon: IconMotorbike,
    //   url: "/delivery/lista-pedidos",
    //   breadcrumbs: false,
    // },
    {
      id: "payAccount",
      title: "PAGOS A CUENTA",
      type: "item",
      icon: IconCash,
      url: "/pago-cuenta",
      breadcrumbs: true,
    },
    {
      id: "reportes",
      title: "REPORTES",
      type: "item",
      icon: IconReceipt,
      url: "/reportes",
      breadcrumbs: false,
    },
    // {
    //   id: "inventarioConsumoDiario",
    //   title: "MOVIMIENTO DIARIO",
    //   type: "item",
    //   icon: IconCalendarEvent,
    //   url: "/inventario/movimiento-diario",
    //   breadcrumbs: false,
    // },
    // {
    //   id: "kardex",
    //   title: "INGRESOS",
    //   type: "collapse",
    //   icon: IconReportAnalytics,
    //   children: [
    //     {
    //       id: "ingresos",
    //       title: "REGISTRO COMPRAS",
    //       type: "item",
    //       url: "/registro-compras",
    //       breadcrumbs: false,
    //     },
    //     {
    //       id: "reportGastos",
    //       title: "REPORTES DE GASTOS",
    //       type: "item",
    //       url: "/reporte-gastos",
    //       breadcrumbs: false,
    //     },
    //     {
    //       id: "stockProductos",
    //       title: "STOCK DE PRODUCTOS",
    //       type: "item",
    //       url: "/stock-productos",
    //       breadcrumbs: false,
    //     },
    //     {
    //       id: "kardex",
    //       title: "KARDEX",
    //       type: "item",
    //       url: "/kardex",
    //       breadcrumbs: false,
    //     },
    //   ],
    // },
    {
      id: "sunat",
      title: "SUNAT",
      type: "collapse",
      icon: IconReceiptTax,
      children: [
        {
          id: "pendientes",
          title: "PENDIENTES",
          type: "item",
          url: "/facturacion/pendientes",
          breadcrumbs: false,
        },
        {
          id: "comprobante",
          title: "COMPROBANTES",
          type: "item",
          url: "/facturacion/comprobantes-electronicos",
          breadcrumbs: false,
        },
        // {
        //   id: "factEmpresa",
        //   title: "GENERAR EMPRESA",
        //   type: "item",
        //   url: "/facturacion/generar-empresa",
        //   breadcrumbs: false,
        // },
        // {
        //   id: "generarCompro",
        //   title: "GENERAR COMPROBANTE",
        //   type: "item",
        //   url: "/facturacion/generar-comprobante",
        //   breadcrumbs: false,
        // },
      ],
    },

    // {
    //   id: "print",
    //   title: "IMPRESIÓN",
    //   type: "item",
    //   icon: PrintIcon,
    //   url: "/impresion",
    //   breadcrumbs: false,
    // },

    // {
    //   id: "people",
    //   title: "PERSONAS",
    //   type: "collapse",
    //   icon: IconUser,
    //   children: [
    //     {
    //       id: "clients",
    //       title: "CLIENTES",
    //       type: "item",
    //       icon: IconFriends,
    //       url: "/clientes",
    //       breadcrumbs: true,
    //     },

    //     {
    //       id: "provider",
    //       title: "PROVEEDORES",
    //       type: "item",
    //       icon: IconUsers,
    //       url: "/proveedores",
    //       breadcrumbs: true,
    //     },
    //     {
    //       id: "users",
    //       title: "TRABAJADORES",
    //       type: "item",
    //       icon: IconAffiliate,
    //       url: "/usuarios",
    //       breadcrumbs: true,
    //     },
    //   ],
    // },
    // {
    //   id: "imports",
    //   title: "IMPORTACIÓN",
    //   type: "item",
    //   icon: IconUpload,
    //   url: "/importacion",
    //   breadcrumbs: false,
    // },
    // {
    //   id: "configuration",
    //   title: "CONFIGURACIÓN",
    //   type: "collapse",
    //   icon: SettingsIcon,
    //   children: [
    //     {
    //       id: "carta",
    //       title: "CARTA",
    //       type: "collapse",
    //       icon: MenuBookIcon,
    //       children: [
    //         {
    //           id: "categories",
    //           title: "CATEGORIAS",
    //           icon: CategoryIcon,
    //           type: "item",
    //           url: "/configuracion/categorias",
    //           breadcrumbs: true,
    //         },
    //         {
    //           id: "subcategories",
    //           title: "SUB CATEGORIAS",
    //           icon: ChangeHistoryIcon,
    //           type: "item",
    //           url: "/configuracion/subcategorias",
    //           breadcrumbs: true,
    //         },
    //         {
    //           id: "dishes",
    //           title: "PLATOS",
    //           icon: IconToolsKitchen2,
    //           type: "item",
    //           url: "/configuracion/platos",
    //           breadcrumbs: true,
    //         },
    //         {
    //           id: "promo",
    //           title: "PROMOCIONES",
    //           icon: IconDiscount2,
    //           type: "item",
    //           url: "/configuracion/menu/promociones",
    //           breadcrumbs: true,
    //         },
    //         {
    //           id: "menu-carta",
    //           title: "MENU",
    //           icon: IconSoup,
    //           type: "item",
    //           url: "/configuracion/menu/menu",
    //           breadcrumbs: true,
    //         },
    //         {
    //           id: "ingredients",
    //           title: "INGREDIENTES",
    //           type: "item",
    //           icon: IconMeat,
    //           url: "/configuracion/ingredientes",
    //           breadcrumbs: true,
    //         },
    //         {
    //           id: "drinks",
    //           title: "BEBIDAS",
    //           icon: IconGlassFull,
    //           type: "item",
    //           url: "/configuracion/bebidas",
    //           breadcrumbs: true,
    //         },
    //         {
    //           id: "consumables",
    //           title: "CONSUMIBLES",
    //           type: "item",
    //           icon: IconBottle,
    //           url: "/configuracion/consumibles",
    //           breadcrumbs: true,
    //         },
    //         {
    //           id: "destinosimpresion",
    //           title: "DESTINOS DE IMPRESIÓN",
    //           type: "item",
    //           icon: IconPrinter,
    //           url: "/destinos/impresion",
    //           breadcrumbs: false,
    //         },
    //         // {
    //         //   id: "units",
    //         //   title: "UNIDADES DE MEDICIÓN",
    //         //   type: "item",
    //         //   icon: IconMeat,
    //         //   url: "/configuracion/unidades",
    //         //   breadcrumbs: true,
    //         // },
    //       ],
    //     },
    //     {
    //       id: "company",
    //       title: "INSTALACIÓN",
    //       type: "collapse",
    //       icon: IconSitemap,
    //       children: [
    //         {
    //           id: "level",
    //           title: "NIVELES",
    //           icon: IconChartArrowsVertical,
    //           type: "item",
    //           url: "/menu/nivel",
    //           breadcrumbs: true,
    //         },
    //         {
    //           id: "area",
    //           title: "ÁREAS",
    //           icon: IconBoxModel,
    //           type: "item",
    //           url: "/menu/area",
    //           breadcrumbs: true,
    //         },
    //         {
    //           id: "tables",
    //           title: "MESAS",
    //           icon: IconBoxMultiple,
    //           type: "item",
    //           url: "/menu/mesas",
    //           breadcrumbs: true,
    //         },
    //         {
    //           id: "destinosimpresionArea",
    //           title: "DESTINOS DE IMPRESIÓN",
    //           type: "item",
    //           icon: IconPrinter,
    //           url: "/destinos-area/impresion",
    //           breadcrumbs: false,
    //         },
    //       ],
    //     },
    //     {
    //       id: "methodPay",
    //       title: "MÉTODOS DE PAGO",
    //       type: "item",
    //       icon: PaymentIcon,
    //       url: "/configuracion/metodo-pago",
    //       breadcrumbs: true,
    //     },
    //     {
    //       id: "waiterConfig",
    //       title: "MOZO",
    //       type: "item",
    //       icon: EmojiPeopleIcon,
    //       url: "/configuracion/mozo",
    //       breadcrumbs: true,
    //     },
    //     {
    //       id: "workingMenu",
    //       title: "CARTA DE TRABAJO",
    //       type: "item",
    //       icon: IconToolsKitchen,
    //       url: "/configuracion/carta-trabajo",
    //       breadcrumbs: true,
    //     },
    //     {
    //       id: "deliveryConfig",
    //       title: "DELIVERY",
    //       type: "item",
    //       icon: IconMotorbike,
    //       url: "/configuracion/delivery",
    //       breadcrumbs: true,
    //     },
    //     {
    //       id: "bannerConfig",
    //       title: "BANNER WEB",
    //       type: "item",
    //       icon: IconAd,
    //       url: "/configuracion/banner",
    //       breadcrumbs: true,
    //     },
    //     {
    //       id: "box",
    //       title: "CONFIG CAJAS",
    //       type: "item",
    //       icon: InboxIcon,
    //       url: "/configuracion/caja",
    //       breadcrumbs: true,
    //     },
    //   ],
    // },
    // {
    //   id: "claims",
    //   title: "RECLAMOS",
    //   type: "item",
    //   icon: ReportIcon,
    //   url: "/reclamos",
    //   breadcrumbs: false,
    // },
  ],
};
export default admin;
