// MOISES BACK
// const api = process.env.REACT_APP_API ?? "http://192.168.1.135:4000/api";
// const apiSocket = process.env.REACT_APP_API_SOCKET ?? "http://192.168.1.135:4000";
// const apiReclamos = process.env.REACT_APP_API_RECLAMOS ?? "http://192.168.1.135:8080/api";

// FABRIZIO BACK
// const api = process.env.REACT_APP_API ?? "http://192.168.1.105:4001/api";
// const apiSocket = process.env.REACT_APP_API_SOCKET ?? "http://192.168.1.105:4001";
// const apiReclamos = process.env.REACT_APP_API_RECLAMOS ?? "http://192.168.1.122:8080/api";

const api = process.env.REACT_APP_API ?? "https://back.napfood.com/api";
const apiSocket = process.env.REACT_APP_API_SOCKET ?? "https://back.napfood.com/";
const apiReclamos = process.env.REACT_APP_API_RECLAMOS ?? "https://back.reclamaciones.napnegocios.com/api";

export { api, apiSocket, apiReclamos };
