// assets
import {
  IconBuildingBank,
  IconBorderAll,
  IconReceiptTax,
  IconListCheck,
  IconClipboardList,
  IconMotorbike,
} from "@tabler/icons";
import PrintIcon from "@material-ui/icons/Print";

//-----------------------|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||-----------------------//
const cajeroSinCuadre = {
  id: "menu",
  type: "group",
  title: "CAJA",
  children: [
    {
      id: "cashier",
      title: "CAJA",
      type: "item",
      icon: IconBuildingBank,
      url: "/caja",
      breadcrumbs: false,
    },
    // {
    //   id: "freetables",
    //   title: "MESAS DISPONIBLES",
    //   type: "item",
    //   icon: IconBorderAll,
    //   url: "/freetables",
    //   breadcrumbs: false,
    // },
    // {
    //   id: "orders",
    //   title: "PEDIDOS",
    //   type: "item",
    //   icon: IconListCheck,
    //   url: "/pedidos",
    //   breadcrumbs: false,
    // },
    // {
    //   id: "ordersList",
    //   title: "LISTA DE PEDIDOS",
    //   type: "item",
    //   icon: IconClipboardList,
    //   url: "/lista/pedidos",
    //   breadcrumbs: false,
    // },
    // {
    //   id: "deliveryOrdersList",
    //   title: "PEDIDOS DELIVERY",
    //   type: "item",
    //   icon: IconMotorbike,
    //   url: "/delivery/lista-pedidos",
    //   breadcrumbs: false,
    // },
    // {
    //   id: "clients",
    //   title: "CLIENTES",
    //   type: "item",
    //   icon: IconFriends,
    //   url: "/clientes",
    //   breadcrumbs: true,
    // },
    // {
    //   id: "print",
    //   title: "IMPRESIÓN",
    //   type: "item",
    //   icon: PrintIcon,
    //   url: "/impresion",
    //   breadcrumbs: false,
    // },
    {
      id: "sunat",
      title: "SUNAT",
      type: "collapse",
      icon: IconReceiptTax,
      children: [
        {
          id: "pendientes",
          title: "PENDIENTES",
          type: "item",
          url: "/facturacion/pendientes",
          breadcrumbs: true,
        },
        {
          id: "comprobante",
          title: "COMPROBANTES",
          type: "item",
          url: "/facturacion/comprobantes-electronicos",
          breadcrumbs: false,
        },
      ],
    },
  ],
};

export default cajeroSinCuadre;
