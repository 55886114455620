import axios from "axios";
import { api } from "src/utils/api";
import user from "src/utils/userDetails";

export const getMethodPayment = async () => {
  let res = await axios.get(`${api}/methodpay/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const postMethodPayment = async (data) => {
  let res = await axios.post(`${api}/methodpay/${user.restaurant._id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
export const putMethodPayment = async (data, id) => {
  let res = await axios.put(`${api}/methodpay/${id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
