import axios from "axios";
import { api } from "src/utils/api";
import user from "src/utils/userDetails";

export const getIngredients = async () => {
  const res = await axios.get(`${api}/ingredient/ingredient/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getAllIngredients = async () => {
  const res = await axios.get(`${api}/ingredient/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getRegisterInventory = async () => {
  const res = await axios.get(`${api}/ingredient/registers-inventory/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const postIngredients = async (data) => {
  const res = await axios.post(`${api}/ingredient`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const putIngredients = async (data, id) => {
  const res = await axios.put(`${api}/ingredient/${id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const deleteIngredients = async (id) => {
  const res = await axios.deconste(`${api}/ingredient/${id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const sendRegisterInventory = async (data) => {
  const res = await axios.post(
    `${api}/ingredient/register-inventory/${user.restaurant._id}`,
    data,
    { headers: { Authorization: user.token } }
  );
  return res.data;
};

export const putStock = async (id, data) => {
  const res = await axios.put(`${api}/ingredient/stock/${id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
