// assets
import { IconListCheck, IconBorderAll, IconClipboardList } from "@tabler/icons";
//-----------------------|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||-----------------------//
const mozo = {
  id: "menu",
  type: "group",
  title: "MOZO",
  children: [
    // {
    //   id: "freetables",
    //   title: "MESAS DISPONIBLES",
    //   type: "item",
    //   icon: IconBorderAll,
    //   url: "/freetables",
    //   breadcrumbs: false,
    // },
    // {
    //   id: "orders",
    //   title: "PEDIDOS",
    //   type: "item",
    //   icon: IconListCheck,
    //   url: "/pedidos",
    //   breadcrumbs: false,
    // },
    // {
    //   id: "ordersList",
    //   title: "LISTA DE PEDIDOS",
    //   type: "item",
    //   icon: IconClipboardList,
    //   url: "/lista/pedidos",
    //   breadcrumbs: false,
    // },
  ],
};

export default mozo;
