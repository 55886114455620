import axios from "axios";
import { api } from "src/utils/api";
import user from "src/utils/userDetails";

export const getArea = async () => {
  let res = await axios.get(`${api}/area/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getFreeAreas = async (nivelId) => {
  let res = await axios.get(
    `${api}/area/free/${user.restaurant._id}/${nivelId}`,
    { headers: { Authorization: user.token } }
  );
  return res.data;
};

export const postArea = async (data) => {
  let res = await axios.post(`${api}/area`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const putArea = async (data, id) => {
  let res = await axios.put(`${api}/area/${id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const deleteArea = async (id) => {
  let res = await axios.delete(`${api}/area/${id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const asignLevelAreaToTable = async (idArea, idLevel, idTable) => {
  let res = await axios.put(
    `${api}/table/asign-area-nivel/${idArea}/${idLevel}/${idTable}`,
    {},
    { headers: { Authorization: user.token } }
  );
  return res.data;
};

export const getAreaToAsignPrinter = async (id) => {
  let res = await axios.get(
    `${api}/area/assign-to-printer/${id}/${user.restaurant._id}`,
    { headers: { Authorization: user.token } }
  );
  return res.data;
};

export const putAreasAsignToPrinter = async (data) => {
  let res = await axios.put(`${api}/area/assign/printer`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};