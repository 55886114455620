import axios from "axios";
import { api } from "src/utils/api";
import user from "src/utils/userDetails";

export const getSubCategories = async () => {
  let res = await axios.get(`${api}/subcategory/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getFreeSubCategories = async (categoriaId) => {
  let res = await axios.get(`${api}/subcategory/free/${user.restaurant._id}/${categoriaId}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const postSubCategories = async (data) => {
  let res = await axios.post(`${api}/subcategory/${user.restaurant._id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const putSubCategories = async (data, id) => {
  let res = await axios.put(`${api}/subcategory/${id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const postPromotionSubCategories = async (data, id) => {
  let res = await axios.post(`${api}/subcategory/add-promotion/${id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const deleteSubCategories = async (id) => {
  let res = await axios.delete(`${api}/subcategory/${id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const asignDishes = async (idSubCat, data) => {
  let res = await axios.put(`${api}/subcategory/asign-dish/${idSubCat}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
export const fontSubCategories = async (data, id) => {
  let res = await axios.put(`${api}/subcategory/font-color-size/${id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
export const fontDishBySubCategorie = async (data, id) => {
  let res = await axios.put(`${api}/subcategory/dishes/font-color-size/${id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
