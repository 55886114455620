import axios from "axios";
import { api } from "src/utils/api";
import user from "src/utils/userDetails";

export const getLevel = async () => {
  let res = await axios.get(`${api}/nivel/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const postLevel = async (data) => {
  let res = await axios.post(`${api}/nivel`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const putLevel = async (data, id) => {
  let res = await axios.put(`${api}/nivel/${id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const deleteLevel = async (id) => {
  let res = await axios.delete(`${api}/nivel/${id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const asignAreas = async (idLevel, data) => {
  let res = await axios.put(`${api}/nivel/asign-area/${idLevel}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
