import axios from "axios";
import { api } from "src/utils/api";
import user from "src/utils/userDetails";

export const getDashboarInfoOne = async (day, month, year) => {
  let res = await axios.get(
    `${api}/dashboard/${user.restaurant._id}/${day}/${month}/${year}`,
    { headers: { Authorization: user.token } }
  );
  return res.data;
};
