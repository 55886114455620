import React, { useState, createContext, useEffect } from "react";
import moment from "moment-timezone";
import { useHistory } from "react-router";
import { getPendingFact } from "src/utils/requests";
import { notification } from "antd";

import user from "src/utils/userDetails";
import userDetails from "src/utils/userDetails";

export const OrderContext = createContext();

const url = window.location.pathname;

const OrderContextProvider = (props) => {
  const rolesAllowNotification = ["admin", "cajero", "supervisor", "mozo_mod"];

  const navigate = useHistory();

  const getInitialData = async () => {
    try {
      const res = await getPendingFact();

      const findRole = user.roles.find((role) => rolesAllowNotification.includes(role));

      if (
        res > 0 &&
        findRole &&
        url !== "/impresion" &&
        !url.includes("/carta") &&
        userDetails?.restaurant?._id !== "61a8dd8ab749ee11172dfed7"
      ) {
        navigate.push("/facturacion/pendientes");
        notification.warn({
          message: "Facturacion",
          description: `TIENE ${res} COMPROBANTES PENDIENTES, POR FAVOR ENVIARLOS.`,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // Si esta en la pagina de impresion no se recarga la pagina
    if (window.location.pathname !== "/impresion") {
      getInitialData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [redirect, setRedirect] = useState(false);
  const [step, setStep] = useState(0);
  const [edit, setEdit] = useState(false);
  const [idOrder, setIdOrder] = useState(null);
  const [from, setFrom] = useState(null);
  const [typeDelivery, setTypeDelivery] = useState(null);
  const [orderContext, setOrderContext] = useState([
    { name: "NIVEL", detail: { name: "", _id: "" } },
    { name: "ÁREA", detail: { name: "", _id: "" } },
    { name: "MESA", detail: { name: "", _id: "" } },
    { name: "PLATOS", detail: { concepto: [], regular: [], promo: [], dynamicPromo: [] } },
  ]);
  const [paymentContext, setPaymentContext] = useState({
    open: false,
    data: null,
  });
  const [sunatRefreshNotification, setSunatRefreshNotification] = useState(false);
  const [deliveryProgramadoContext, setDeliveryProgramadoContext] = useState({
    date: `${moment().format("YYYY-MM-DD")}T${moment().format("HH:mm")}`,
    estado: false,
    motorizado: null,
  });
  const [payment, setPayment] = useState(null);
  const [mountPayment, setMountPayment] = useState(null);
  const [printBluetooh, setPrintBluetooh] = useState({});
  const [linkDireccion, setLinkDireccion] = useState("");
  const [waiter, setWaiter] = useState(null);

  const restarAllOrder = () => {
    setIdOrder(null);
    setOrderContext([
      { name: "NIVEL", detail: { name: "", _id: "" } },
      { name: "ÁREA", detail: { name: "", _id: "" } },
      { name: "MESA", detail: { name: "", _id: "" } },
      { name: "PLATOS", detail: { concepto: [], regular: [], promo: [], dynamicPromo: [] } },
    ]);
    setStep(0);
    setEdit(false);
    setTypeDelivery(null);
    // if (edit) history.goBack();
  };

  return (
    <OrderContext.Provider
      value={{
        orderContext,
        setOrderContext,
        step,
        setStep,
        edit,
        setEdit,
        idOrder,
        setIdOrder,
        typeDelivery,
        setTypeDelivery,
        from,
        setFrom,
        paymentContext,
        setPaymentContext,
        sunatRefreshNotification,
        setSunatRefreshNotification,
        setDeliveryProgramadoContext,
        deliveryProgramadoContext,
        redirect,
        setRedirect,
        payment,
        setPayment,
        mountPayment,
        setMountPayment,
        printBluetooh,
        setPrintBluetooh,
        linkDireccion,
        setLinkDireccion,
        waiter,
        setWaiter,
        restarAllOrder,
      }}
    >
      {props.children}
    </OrderContext.Provider>
  );
};
export default OrderContextProvider;
