import axios from "axios";
import { api } from "src/utils/api";
import user from "src/utils/userDetails";

export const postPromotion = async (data) => {
  let res = await axios.post(`${api}/promotion/${user.restaurant._id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
export const getPromotion = async () => {
  let res = await axios.get(`${api}/promotion/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
export const putPromotion = async (data, id) => {
  let res = await axios.put(`${api}/promotion/${id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
export const deletePromo = async (id) => {
  let res = await axios.delete(`${api}/promotion/${id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
export const getPromotionCarta = async (id = user.restaurant._id) => {
  let res = await axios.get(`${api}/promotion/carta/${id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getPromotionMenu = async () => {
  let res = await axios.get(`${api}/promotion/menu/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
