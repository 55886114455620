import axios from "axios";
import { api } from "src/utils/api";
import user from "src/utils/userDetails";

export const getCategories = async () => {
  let res = await axios.get(`${api}/category/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const postCategories = async (data) => {
  let res = await axios.post(`${api}/category/${user.restaurant._id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const putCategories = async (data, id) => {
  let res = await axios.put(`${api}/category/${id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const deleteCategories = async (id) => {
  let res = await axios.delete(`${api}/category/${id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const asignSubCategories = async (idCat, data) => {
  let res = await axios.put(`${api}/category/asign-subcategory/${idCat}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
