import axios from "axios";
import { api } from "src/utils/api";
import user from "src/utils/userDetails";

export const getAreaByLevel = async (idLevel) => {
  let res = await axios.get(`${api}/nivel/area/${idLevel}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getTablesByArea = async (idArea) => {
  let res = await axios.get(`${api}/table/area/${idArea}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getAllDishes = async () => {
  let res = await axios.get(`${api}/category/all/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const postOrder = async (data, id = user.restaurant._id) => {
  let res = await axios.post(`${api}/order/${id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const putOrder = async (data, id) => {
  let res = await axios.put(`${api}/order/${id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getOrder = async (date) => {
  let res = await axios.get(`${api}/order/${user.restaurant._id}/${date}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const deleteOrden = async (id) => {
  let res = await axios.delete(`${api}/order/${id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getAllOrder = async () => {
  let res = await axios.get(`${api}/order/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getAllOrderByDate = async (data) => {
  let res = await axios.post(`${api}/order/report/historical/${user.restaurant._id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const changeOrderStatus = async (data, id) => {
  let res = await axios.put(`${api}/order/status/${id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const postSubaccounts = async (data, id) => {
  let res = await axios.post(`${api}/order/subaccounts/${id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getPendingPrinter = async () => {
  let res = await axios.get(
    `${api}/order/printing/${user.restaurant._id}/${new Date().getDate()}/${
      new Date().getMonth() + 1
    }/${new Date().getFullYear()}`,
    { headers: { Authorization: user.token } }
  );
  return res.data;
};

export const updatePrintStatus = async (data) => {
  let res = await axios.put(`${api}/order/print/orders`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getOrderByDay = async (day, month, year) => {
  let res = await axios.get(`${api}/order/paid/${user.restaurant._id}/${day}/${month}/${year}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getWebOrders = async () => {
  let res = await axios.get(`${api}/order/web/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
export const changeStatusWebOrder = async (data, id) => {
  let res = await axios.put(`${api}/order/status-web/${id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
export const changeOrderVoucher = async (data, id) => {
  let res = await axios.put(`${api}/order/comprobante/${id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
export const getAutomaticOrderPrint = async () => {
  let res = await axios.get(`${api}/order/print/paid/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const printAutomaticPaidOrders = async (data) => {
  let res = await axios.put(`${api}/order/print/paid/orders`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getOrderKitchen = async () => {
  let res = await axios.get(`${api}/order/incomplete/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const updateOrderKtichen = async (order) => {
  let res = await axios.put(
    `${api}/order/complete/${order}`,
    {},
    { headers: { Authorization: user.token } }
  );
  return res.data;
};

export const deleteAllOrders = async () => {
  let res = await axios.delete(`${api}/order/allOrders/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
