import React, { useState, useEffect, Fragment } from "react";
import SelectRestaurants from "src/components/Selects/SelectRestaurants";
import { notification } from "antd";
import { getRestaurants } from "src/utils/requests";
import SessionStorageService from "src/auth/SessionStorageService";
import SESSION_NAME from "src/utils/session";
import user from "src/utils/userDetails";

const ChangeRestaurant = () => {
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (user.roles?.includes("superadmin") || user.rol === "superadmin") obtainCompanies();
    else setData(user.restaurants);
  }, []);

  useEffect(() => {
    if (!Boolean(user.restaurant)) {
      if (data.length > 0) {
        let res = SessionStorageService.get(SESSION_NAME);
        res.value.data["restaurant"] = data[0];
        SessionStorageService.set(SESSION_NAME, res);
        setSelected(data[0]._id);
      }
    } else {
      setSelected(user.restaurant._id);
    }
  }, [data]);

  const obtainCompanies = async () => {
    setLoading(true);
    try {
      const result = await getRestaurants();
      setData(result);
    } catch (error) {
      notification["error"]({
        message: "Oops!",
        description: `Ocurrió un error al obtener la información. ${error}`,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleRestaurante = (e) => {
    try {
      let res = SessionStorageService.get(SESSION_NAME);
      let finder = data.find((el) => el._id === e);
      res.value.data["restaurant"] = finder;
      SessionStorageService.set(SESSION_NAME, res);
      setSelected(e);
      window.location.reload();
    } catch (error) {
      notification["error"]({
        message: "Oops!",
        description: `Ocurrió un error. ${error}`,
      });
    }
  };

  return (
    <Fragment>
      {data.length > 0 && (
        <SelectRestaurants
          value={selected}
          onChange={handleRestaurante}
          data={data}
          loading={loading}
        />
      )}
    </Fragment>
  );
};

export default ChangeRestaurant;
