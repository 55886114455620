import axios from "axios";
import { api } from "src/utils/api";
import user from "src/utils/userDetails";

export const getAllDeliveryOrders = async (data) => {
  let res = await axios.post(`${api}/order/delivery/${user.restaurant._id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getDeliveryOrdersByUser = async (userId, data) => {
  let res = await axios.post(`${api}/order/delivery-user/${userId}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const changeDelivery = async (orderId, data) => {
  let res = await axios.put(`${api}/order/delivery/${orderId}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const changeStatusDeliveryOrder = async (orderId, data) => {
  let res = await axios.put(`${api}/order/is-send/${orderId}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getFreeDeliveryOrders = async () => {
  let res = await axios.get(`${api}/order/delivery-free/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const asingDeliveryOrder = async (orderId, data) => {
  let res = await axios.put(`${api}/order/asign/delivery/${orderId}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
