import React from "react";
import user from "src/utils/userDetails";
import { Avatar } from "@material-ui/core";

let logo = user && user.data && user.data.restaurant;

const Logo = () => {
  return (
    <Avatar
      src={Boolean(logo) ? logo.banner : "/images/header.png"}
      alt="Logo de la empresa"
      // height="50px"
      style={{ height: "80px", width: "80px", marginTop: "5px" }}
    />
  );
};

export default Logo;
