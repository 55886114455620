import axios from "axios";
import { apiReclamos } from "src/utils/api";
import user from "src/utils/userDetails";

export const postClaim = async (data) => {
  const res = await axios.post(`${apiReclamos}/create/claim`, data);
  return res.data;
};

export const getClaims = async () => {
  let res = await axios.get(`${apiReclamos}/claims/${user.restaurant._id}/napfood`);
  return res.data;
};

export const putClaim = async (data, idClaim) => {
  let res = await axios.put(`${apiReclamos}/claim/${idClaim}`, data);
  return res.data;
};
