import axios from "axios";
import { api } from "src/utils/api";
import user from "src/utils/userDetails";

export const getWaitList = async () => {
  let res = await axios.get(
    `${api}/restaurant/wait-list/${user.restaurant._id}`,
    {
      headers: { Authorization: user.token },
    }
  );
  return res.data;
};

export const postWaitList = async (data) => {
  let res = await axios.post(
    `${api}/restaurant/wait-list/${user.restaurant._id}`,
    data,
    {
      headers: { Authorization: user.token },
    }
  );
  return res.data;
};
