import axios from "axios";
import { api } from "src/utils/api";
import user from "src/utils/userDetails";

export const getPrinter = async () => {
  let res = await axios.get(`${api}/printer/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const postPrinter = async (data) => {
  let res = await axios.post(`${api}/printer`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const putPrinter = async (data, id) => {
  let res = await axios.put(`${api}/printer/${id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const deletePrinter = async (id) => {
  let res = await axios.delete(`${api}/printer/${id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
