// assets
import { IconUsers, IconMeat } from "@tabler/icons";

//-----------------------|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||-----------------------//
const almacen = {
  id: "menu",
  type: "group",
  title: "ALMACÉN",
  children: [
    {
      id: "provider",
      title: "PROVEEDORES",
      type: "item",
      icon: IconUsers,
      url: "/proveedores",
      breadcrumbs: true,
    },
    {
      id: "ingredients",
      title: "INGREDIENTES",
      type: "item",
      icon: IconMeat,
      url: "/configuracion/ingredientes",
      breadcrumbs: true,
    },
  ],
};
export default almacen;
