import axios from "axios";
import { api } from "src/utils/api";
import user from "src/utils/userDetails";

export const getUsers = async () => {
  let res = await axios.get(`${api}/user/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const postUsers = async (data, id) => {
  let res = await axios.post(`${api}/auth/signup/${id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const putUsers = async (data, id) => {
  let res = await axios.put(`${api}/user/${id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const deleteUsers = async (id) => {
  let res = await axios.delete(`${api}/user/${id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const changePassword = async (data) => {
  let res = await axios.post(`${api}/auth/change_password`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const assignAreasToUsers = async (data, id) => {
  let res = await axios.put(`${api}/user/assign-areas/${id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const assignBoxToUsers = async (data, id) => {
  let res = await axios.put(`${api}/user/caja/${id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getUsersByRol = async (rol) => {
  let res = await axios.get(`${api}/user/${rol}/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
