import React from "react";
import { Redirect, Route } from "react-router-dom";
import DashboardLayout from "./MainLayout";
import Page401 from "src/views/errors/Page401";
import user from "src/utils/userDetails";

const AppRoutes = ({ component: Component, path, listRoles, isPrivate, exact, ...rest }) => {
  let flag = false;
  listRoles?.forEach((element) => {
    if (user.roles?.includes(element) || user.rol === "superadmin") {
      flag = true;
    }
  });
  return (
    <Route
      exact={exact}
      path={path}
      render={(props) =>
        isPrivate && (!user || !Boolean(user.token) || !user.roles) ? (
          <Redirect to={{ pathname: "/login" }} />
        ) : isPrivate ? (
          flag ? (
            <DashboardLayout>
              <Component {...props} />
            </DashboardLayout>
          ) : (
            <Page401 />
          )
        ) : (
          <Component {...props} />
        )
      }
      {...rest}
    />
  );
};

export default AppRoutes;
