// assets
import { IconMotorbike } from "@tabler/icons";

//-----------------------|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||-----------------------//
const envios = {
  id: "menu",
  type: "group",
  title: "ENVÍOS",
  children: [
    {
      id: "deliveryOrdersList",
      title: "PEDIDOS DELIVERY",
      type: "item",
      icon: IconMotorbike,
      url: "/delivery/lista-pedidos",
      breadcrumbs: false,
    },
    {
      id: "deliveryOrders",
      title: "DELIVERISTA",
      type: "item",
      icon: IconMotorbike,
      url: "/deliverista",
      breadcrumbs: false,
    },
  ],
};

export default envios;
