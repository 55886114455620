import axios from "axios";
import { api } from "src/utils/api";
import user from "src/utils/userDetails";

export const postOpenCashRegister = async (data, boxId) => {
  let res = await axios.post(
    `${api}/register/${user.restaurant._id}/${boxId}`,
    data,
    {
      headers: { Authorization: user.token },
    }
  );
  return res.data;
};

export const getLastRegister = async (boxId) => {
  let res = await axios.get(
    `${api}/register/last-register/${user.restaurant._id}/${boxId}`,
    { headers: { Authorization: user.token } }
  );
  return res.data;
};

export const postManualMovements = async (registerId, value, type, description) => {
  let res = await axios.put(
    `${api}/register/add/movement/${registerId}`,
    { description, value, type },
    { headers: { Authorization: user.token } }
  );
  return res.data;
};

export const deleteManualMovements = async (registerId, movement) => {
  let res = await axios.put(
    `${api}/register/remove/movement/${registerId}/${movement}`,
    {},
    { headers: { Authorization: user.token } }
  );
  return res.data;
}

export const getSalidasPerDay = async (day, month, year, boxId) => {
  let res = await axios.get(
    `${api}/entry/${user.restaurant._id}/${day}/${month}/${year}/${boxId}`,
    { headers: { Authorization: user.token } }
  );
  return res.data;
};



export const getHistorico = async (boxId) => {
  let res = await axios.get(`${api}/register/${user.restaurant._id}/${boxId}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getDaliyConsum = async (day, month, year, boxId) => {
  let res = await axios.get(
    `${api}/order/daily-consum/${day}/${month}/${year}/${user.restaurant._id}/${boxId}`,
    { headers: { Authorization: user.token } }
  );
  return res.data;
};

export const saveCashRegister = async (idRegister, data) => {
  let res = await axios.post(`${api}/register/save-box/${idRegister}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const closeCashRegister = async (idRegister, data) => {
  let res = await axios.post(`${api}/register/close-box/${idRegister}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const downloadExcelCuadre = async (idRegister) => {
  let res = await axios.post(
    `${api}/order/export/report/close-box/${idRegister}`,
    {
      headers: { Authorization: user.token },
    }
  );
  return res.data;
};

export const getReportCuadre = async (idRegister) => {
  let res = await axios.get(`${api}/order/report/close-box/${idRegister}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const putAutomaticCuadrePrint = async (idRegister, data) => {
  let res = await axios.put(`${api}/register/printing/${idRegister}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getAutomaticCuadrePrint = async () => {
  let res = await axios.get(`${api}/register/print/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const updateAutomaticCuadrePrintState = async (data) => {
  let res = await axios.put(`${api}/register/after-printing`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
