import axios from "axios";
import { api } from "src/utils/api";
import user from "src/utils/userDetails";

export const sendContactEmail = async (data) => {
  let res = await axios.post(`${api}/public/customer`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
