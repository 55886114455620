import axios from "axios";
import { api } from "src/utils/api";
import user from "src/utils/userDetails";

export const getConsumables = async () => {
  let res = await axios.get(
    `${api}/ingredient/consumable/${user.restaurant._id}`,
    { headers: { Authorization: user.token } }
  );
  return res.data;
};
