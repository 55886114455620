import axios from "axios";
import { api } from "src/utils/api";
import user from "src/utils/userDetails";
export const postPayAccount = async (data) => {
  //data={name, customer: idCustomer}
  let res = await axios.post(`${api}/pay-account/${user.restaurant._id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
export const putPayAccount = async (data, id) => {
  let res = await axios.put(`${api}/pay-account/${id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
export const getPayAccount = async () => {
  let res = await axios.get(`${api}/pay-account/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
export const getPayAccountDetails = async (payAccountId) => {
  let res = await axios.get(`${api}/pay-account/details/${payAccountId}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const payAccountGeneratePayment = async (data, payAccountId) => {
  let res = await axios.post(
    `${api}/pay-account/generate-payment/${payAccountId}/${user.restaurant._id}`,
    data,
    { headers: { Authorization: user.token } }
  );
  return res.data;
};
export const getPayAccountHistoric = async (payAccountId) => {
  let res = await axios.get(`${api}/pay-account/historic/${payAccountId}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const addSaldoToPayAccount = async (data, payAccountId) => {
  let res = await axios.post(`${api}/pay-account/add-remaining/${payAccountId}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
