import React from "react";
import { Avatar, FormHelperText, Grid, Typography } from "@material-ui/core";
import { Select } from "antd";
import { makeStyles } from "@material-ui/styles";

const { Option } = Select;
const useStyles = makeStyles((theme) => ({
  select: {
    width: 500,
    margin: "0 20px",
    [theme.breakpoints.down("md")]: { width: 300 },
    [theme.breakpoints.down("sm")]: { width: 150 },
  },
}));

const SelectRestaurants = ({ value, onChange, data, loading }) => {
  const classes = useStyles();

  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };
  const filterOptions = (inputValue, option) => {
    const title = removeAccents(option.props.title.toLowerCase());
    return title.includes(inputValue.toLowerCase());
  };

  return (
    <Grid container className={classes.select}>
      <FormHelperText>RESTAURANTE</FormHelperText>
      <Select
        size="large"
        style={{ width: "100%" }}
        showSearch
        defaultValue={value}
        onChange={(e) => onChange(e)}
        filterOption={filterOptions}
        loading={loading}
        listHeight={550}
      >
        {data.map((el, index) => (
          <Option key={index} value={el._id} title={`${el.tradeName} ${el.country === "peru" && " - " + el.ruc}`}>
            <Grid container direction="row" alignItems="center">
              <Avatar src={el.logo} />
              <Typography sx={{ mx: 1 }}>
                <b>
                  {el.tradeName} {el.sede ? `- ${el.sede}` : ""}
                </b>
              </Typography>
              <FormHelperText>
                <b>{el.country === "peru" && " ─ " + el.ruc}</b>
              </FormHelperText>
            </Grid>
          </Option>
        ))}
      </Select>
    </Grid>
  );
};

export default SelectRestaurants;
