import axios from "axios";
import { api } from "src/utils/api";
import user from "src/utils/userDetails";

export const getOperation = async (idIngredient) => {
  let res = await axios.get(
    `${api}/operation/${idIngredient}/${user.restaurant._id}`,
    { headers: { Authorization: user.token } }
  );
  return res.data;
};

export const postOperation = async (idIngredient, data) => {
  let res = await axios.post(
    `${api}/operation/${idIngredient}/${user.restaurant._id}`,
    data,
    { headers: { Authorization: user.token } }
  );
  return res.data;
};

export const putOperation = async (data, id) => {
  let res = await axios.put(`${api}/operation/${id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const deleteOperation = async (id) => {
  let res = await axios.delete(`${api}/operation/${id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
