// assets
import {
  IconSmartHome,
  IconBuildingBank,
  IconFriends,
  IconBorderAll,
  IconUsers,
  IconUser,
  IconAffiliate,
  IconClipboardList,
  IconToolsKitchen2,
  IconGlassFull,
  IconBottle,
  IconCash,
  IconReportAnalytics,
  IconReceiptTax,
  IconCalendarEvent,
  IconSlice,
  IconCoin,
} from "@tabler/icons";

import SettingsIcon from "@material-ui/icons/Settings";
import MenuBookIcon from "@material-ui/icons/MenuBook";

//-----------------------|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||-----------------------//
const supervisor = {
  id: "menu",
  type: "group",
  title: "SUPERVISOR",
  children: [
    {
      id: "dashboard",
      title: "Inicio",
      type: "item",
      url: "/inicio",
      icon: IconSmartHome,
      breadcrumbs: false,
    },

    {
      id: "cuadreCaja",
      title: "CUADRE DE CAJA",
      type: "item",
      icon: IconCoin,
      url: "/cuadre-caja",
      breadcrumbs: false,
    },

    {
      id: "cashier",
      title: "CAJA",
      type: "item",
      icon: IconBuildingBank,
      url: "/caja",
      breadcrumbs: false,
    },

    // {
    //   id: "freetables",
    //   title: "MESAS DISPONIBLES",
    //   type: "item",
    //   icon: IconBorderAll,
    //   url: "/freetables",
    //   breadcrumbs: false,
    // },

    // {
    //   id: "ordersList",
    //   title: "LISTA DE PEDIDOS",
    //   type: "item",
    //   icon: IconClipboardList,
    //   url: "/lista/pedidos",
    //   breadcrumbs: false,
    // },

    // {
    //   id: "kitchen",
    //   title: "COCINA",
    //   type: "item",
    //   icon: IconSlice,
    //   url: "/lista/cocina",
    //   breadcrumbs: false,
    // },

    // {
    //   id: "payAccount",
    //   title: "PAGOS A CUENTA",
    //   type: "item",
    //   icon: IconCash,
    //   url: "/pago-cuenta",
    //   breadcrumbs: true,
    // },

    // {
    //   id: "inventarioConsumoDiario",
    //   title: "MOVIMIENTO DIARIO",
    //   type: "item",
    //   icon: IconCalendarEvent,
    //   url: "/inventario/movimiento-diario",
    //   breadcrumbs: false,
    // },

    // {
    //   id: "kardex",
    //   title: "INGRESOS",
    //   type: "collapse",
    //   icon: IconReportAnalytics,
    //   children: [
    //     {
    //       id: "stockProductos",
    //       title: "STOCK DE PRODUCTOS",
    //       type: "item",
    //       url: "/stock-productos",
    //       breadcrumbs: false,
    //     },
    //     {
    //       id: "kardex",
    //       title: "KARDEX",
    //       type: "item",
    //       url: "/kardex",
    //       breadcrumbs: false,
    //     },
    //   ],
    // },
    {
      id: "sunat",
      title: "SUNAT",
      type: "collapse",
      icon: IconReceiptTax,
      children: [
        {
          id: "comprobante",
          title: "COMPROBANTES",
          type: "item",
          url: "/facturacion/comprobantes-electronicos",
          breadcrumbs: false,
        },
      ],
    },

    // {
    //   id: "people",
    //   title: "PERSONAS",
    //   type: "collapse",
    //   icon: IconUser,
    //   children: [
    //     {
    //       id: "clients",
    //       title: "CLIENTES",
    //       type: "item",
    //       icon: IconFriends,
    //       url: "/clientes",
    //       breadcrumbs: true,
    //     },
    //     {
    //       id: "provider",
    //       title: "PROVEEDORES",
    //       type: "item",
    //       icon: IconUsers,
    //       url: "/proveedores",
    //       breadcrumbs: true,
    //     },
    //     {
    //       id: "users",
    //       title: "TRABAJADORES",
    //       type: "item",
    //       icon: IconAffiliate,
    //       url: "/usuarios",
    //       breadcrumbs: true,
    //     },
    //   ],
    // },
    // {
    //   id: "configuration",
    //   title: "CONFIGURACIÓN",
    //   type: "collapse",
    //   icon: SettingsIcon,
    //   children: [
    //     {
    //       id: "carta",
    //       title: "Carta",
    //       type: "collapse",
    //       icon: MenuBookIcon,
    //       children: [
    //         {
    //           id: "dishes",
    //           title: "PLATOS",
    //           icon: IconToolsKitchen2,
    //           type: "item",
    //           url: "/configuracion/platos",
    //           breadcrumbs: true,
    //         },

    //         {
    //           id: "drinks",
    //           title: "BEBIDAS",
    //           icon: IconGlassFull,
    //           type: "item",
    //           url: "/configuracion/bebidas",
    //           breadcrumbs: true,
    //         },
    //         {
    //           id: "consumables",
    //           title: "CONSUMIBLES",
    //           type: "item",
    //           icon: IconBottle,
    //           url: "/configuracion/consumibles",
    //           breadcrumbs: true,
    //         },
    //       ],
    //     },
    //   ],
    // },
  ],
};

export default supervisor;
