import axios from "axios";
import { api } from "src/utils/api";
import user from "src/utils/userDetails";

export const getTables = async () => {
  let res = await axios.get(`${api}/table/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const postTables = async (data) => {
  let res = await axios.post(`${api}/table`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const putTables = async (data, id) => {
  let res = await axios.put(`${api}/table/${id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const deleteTables = async (id) => {
  let res = await axios.delete(`${api}/table/${id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getFreeTables = async () => {
  let res = await axios.get(`${api}/table/free/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
