import SESSION_NAME from "src/utils/session";
import SessionStorageService from "src/auth/SessionStorageService";

let all = SessionStorageService.get(SESSION_NAME);

let token = all ? all.value.token : null;

let data = all ? all.value.data : null;
let rol = all ? all.value.data.rol : null;
let roles = all ? all.value.data.roles : null;
let restaurant = all ? all.value.data.restaurant : null;
let restaurants = all ? all.value.data.restaurants : null;

const userDetails = { token, data, rol, restaurant, restaurants, roles };

export default userDetails;
