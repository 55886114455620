import axios from "axios";
import { api } from "src/utils/api";
import user from "src/utils/userDetails";

export const getCustomers = async () => {
  let res = await axios.get(`${api}/customer/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const postCustomers = async (data) => {
  let res = await axios.post(`${api}/customer`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const putCustomers = async (data, id) => {
  let res = await axios.put(`${api}/customer/${id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const deleteCustomers = async (id) => {
  let res = await axios.delete(`${api}/customer/${id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getACustomer = async (data) => {
  let res = await axios.post(`${api}/customer/n-doc/${user.restaurant._id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const confirmWaitList = async (data) => {
  let res = await axios.put(`${api}/restaurant/wait-list/${user.restaurant._id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
