import axios from "axios";
import { api } from "src/utils/api";
import user from "src/utils/userDetails";

export const getShopping = async () => {
  let res = await axios.get(`${api}/entry/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const postShopping = async (data) => {
  let res = await axios.post(`${api}/entry/${user.restaurant._id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
