import axios from "axios";
import { api } from "src/utils/api";
import user from "src/utils/userDetails";

export const getDishes = async () => {
  let res = await axios.get(`${api}/dish/dish/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getDishesAndDrinks = async () => {
  let res = await axios.get(`${api}/dish/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getDrinks = async () => {
  let res = await axios.get(`${api}/dish/drink/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getFreeDishes = async (subcategoryId) => {
  let res = await axios.get(`${api}/dish/free/${user.restaurant._id}/${subcategoryId}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const postDishes = async (data) => {
  let res = await axios.post(`${api}/dish/${user.restaurant._id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const putDishes = async (data, id) => {
  let res = await axios.put(`${api}/dish/${id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const deleteDishes = async (id) => {
  let res = await axios.delete(`${api}/dish/${id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getDishesAndDrinksByPrint = async (idPrint) => {
  let res = await axios.get(`${api}/dish/printered/${idPrint}/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getDishesFree = async () => {
  let res = await axios.get(`${api}/dish/printer/free/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getDishToAsignPrinter = async (id) => {
  let res = await axios.get(`${api}/dish/assign-to-printer/${id}/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const putDishesAsignToPrinter = async (data) => {
  let res = await axios.put(`${api}/dish/assign/printer`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const fontDishes = async (data, id) => {
  let res = await axios.put(`${api}/dish/font-color-size/${id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
