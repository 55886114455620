export const getIgv = (base, igvPercent = 18, recargo = 0, isAmazonia = false) => {
  let igv = 0;

  if (isAmazonia) return igv;

  igv = (base * igvPercent) / (igvPercent + 100 + recargo);

  return igv;
};

export const getRecargoMonto = (base, opGravada, igv) => base - opGravada - igv;

export const getOpGravada = (base, igvPercent = 18, recargo = 0) => {
  let opGravada = 0;

  opGravada = (base * 100) / (igvPercent + 100 + recargo);

  return opGravada;
};
