// assets
import { IconSlice } from "@tabler/icons";

//-----------------------|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||-----------------------//
const cocinero = {
  id: "menu",
  type: "group",
  title: "COCINERO",
  children: [
    // {
    //   id: "ordersList",
    //   title: "LISTA DE PEDIDOS",
    //   type: "item",
    //   icon: IconClipboardList,
    //   url: "/lista/pedidos",
    //   breadcrumbs: false,
    // },
    {
      id: "kitchen",
      title: "COCINA",
      type: "item",
      icon: IconSlice,
      url: "/lista/cocina",
      breadcrumbs: false,
    },
  ],
};

export default cocinero;
