import { IconReceipt, IconReceiptTax } from "@tabler/icons";

const contador = {
  id: "menu",
  type: "group",
  title: "ADMINISTRADOR",
  children: [
    {
      id: "reportes",
      title: "REPORTES",
      type: "item",
      icon: IconReceipt,
      url: "/reportes",
      breadcrumbs: false,
    },
    {
      id: "sunat",
      title: "SUNAT",
      type: "collapse",
      icon: IconReceiptTax,
      children: [
        {
          id: "pendientes",
          title: "PENDIENTES",
          type: "item",
          url: "/facturacion/pendientes",
          breadcrumbs: true,
        },
        {
          id: "comprobante",
          title: "COMPROBANTES",
          type: "item",
          url: "/facturacion/comprobantes-electronicos",
          breadcrumbs: false,
        },
      ],
    },
  ],
};

export default contador;
