import React from "react";

import { useSelector } from "react-redux";

import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline, StyledEngineProvider } from "@material-ui/core";

// routing
import routes from "./routes";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import AppRoute from "./layout/AppRoute";

// defaultTheme
import theme from "./themes";

// project imports
import NavigationScroll from "./layout/NavigationScroll";
import ContextOrders from "./context";
import moment from "moment-timezone";
import user from "src/utils/userDetails";
import "moment/locale/es";
//-----------------------|| APP ||-----------------------//

const App = () => {
  const paises = { peru: "America/Lima", bolivia: "America/La_Paz" };
  moment.tz.setDefault(paises[user.restaurant?.country] ?? "America/Lima");
  moment.locale("es");
  const customization = useSelector((state) => state.customization);

  return (
    <Router>
      <ContextOrders>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme(customization)}>
            <CssBaseline />
            <NavigationScroll>
              <Switch>
                {routes.map((route) => (
                  <AppRoute
                    exact={route.exact}
                    key={route.path}
                    path={route.path}
                    listRoles={route.listRoles}
                    component={route.component}
                    isPrivate={route.isPrivate}
                  />
                ))}
              </Switch>
            </NavigationScroll>
          </ThemeProvider>
        </StyledEngineProvider>
      </ContextOrders>
    </Router>
  );
};

export default App;
