import { lazy } from "react";

import Loadable from "../ui-component/Loadable";
const Dashboard = Loadable(lazy(() => import("../views/dashboard")));
const Home = Loadable(lazy(() => import("../views/pages/Home")));
const RequestDemo = Loadable(lazy(() => import("../views/pages/RequestDemo")));
const WorkersMenu = Loadable(lazy(() => import("../views/pages/WorkersMenu")));
const Page404 = Loadable(lazy(() => import("../views/errors/Page404")));
const Profile = Loadable(lazy(() => import("../views/pages/Profile")));
const Categories = Loadable(lazy(() => import("../views/pages/Categories")));
const SubCategories = Loadable(lazy(() => import("../views/pages/SubCategories")));
const Dishes = Loadable(lazy(() => import("../views/pages/Dishes")));
const Drinks = Loadable(lazy(() => import("../views/pages/Drinks")));
const Level = Loadable(lazy(() => import("../views/pages/Level")));
const Area = Loadable(lazy(() => import("../views/pages/Area")));
const Tables = Loadable(lazy(() => import("../views/pages/Tables")));
const WaitList = Loadable(lazy(() => import("../views/pages/WaitList")));
const Customers = Loadable(lazy(() => import("../views/pages/Customers")));
const Kitchen = Loadable(lazy(() => import("../views/pages/Kitchen")));
const CustomerCuenta = Loadable(lazy(() => import("../views/pages/CustomerCuenta")));
const Providers = Loadable(lazy(() => import("../views/pages/Providers")));
const Ingredients = Loadable(lazy(() => import("../views/pages/Ingredients")));
const Consumables = Loadable(lazy(() => import("../views/pages/Consumables")));
const Orders = Loadable(lazy(() => import("../views/pages/Orders")));
const Users = Loadable(lazy(() => import("../views/pages/Users")));
const OrdersList = Loadable(lazy(() => import("../views/pages/OrdersList")));
const Cashier = Loadable(lazy(() => import("../views/pages/Cashier")));
const FreeTable = Loadable(lazy(() => import("../views/pages/Tables/components/FreeTable")));
const Restaurants = Loadable(lazy(() => import("../views/pages/Restaurants")));
const RestaurantsDelete = Loadable(lazy(() => import("../views/pages/Restaurants/DeleteResturants")));
const RecoveryPassword = Loadable(lazy(() => import("../views/errors/RecoveryPassword")));
const Token = Loadable(lazy(() => import("../views/errors/Token")));
const Login = Loadable(lazy(() => import("../views/pages/Login")));
const ConfigRestaurant = Loadable(lazy(() => import("../views/pages/Restaurants/ConfigRestaurants")));
const Units = Loadable(lazy(() => import("../views/pages/Units")));
const MethodPayment = Loadable(lazy(() => import("../views/pages/MethodPayment")));
const Imports = Loadable(lazy(() => import("../views/pages/Imports")));
const AsignDishesToPrint = Loadable(lazy(() => import("../views/pages/Dishes/AsignDishesToPrint")));
const AsignAreaToPrint = Loadable(lazy(() => import("../views/pages/Area/AsignAreaToPrint")));
const Print = Loadable(lazy(() => import("../views/pages/Print")));
const Kardex = Loadable(lazy(() => import("../views/pages/Kardex")));
const Reports = Loadable(lazy(() => import("../views/pages/Reports")));
const OrdersReport = Loadable(lazy(() => import("../views/pages/Reports/OrdersReport")));
const DishesReport = Loadable(lazy(() => import("../views/pages/Reports/DishesReport")));
const WaitersReport = Loadable(lazy(() => import("../views/pages/Reports/WaitersReport")));
const BallotsReport = Loadable(lazy(() => import("../views/pages/Reports/BallotsReport")));
const InvoiceReport = Loadable(lazy(() => import("../views/pages/Reports/InvoiceReport")));
const ProductsDelete = Loadable(lazy(() => import("../views/pages/Reports/ProductsDelete")));
const SalesNoteReport = Loadable(lazy(() => import("../views/pages/Reports/SalesNoteReport")));
const CreditNoteReport = Loadable(lazy(() => import("../views/pages/Reports/CreditNoteReport")));
const PromotionReport = Loadable(lazy(() => import("../views/pages/Reports/PromotionReport")));
const GeneralReport = Loadable(lazy(() => import("../views/pages/Reports/GeneralReport")));
const MenuReport = Loadable(lazy(() => import("../views/pages/Reports/MenuReport")));
const ShoppingRecord = Loadable(lazy(() => import("../views/pages/ShoppingRecord")));
const HistoryPayment = Loadable(lazy(() => import("../views/pages/Reports/HistoryPayment")));
const MethodPaymentReport = Loadable(lazy(() => import("../views/pages/Reports/MethodPaymentReport")));
const DeliveryReport = Loadable(lazy(() => import("../views/pages/Reports/Delivery")));
const DishesAnulatedReport = Loadable(lazy(() => import("../views/pages/Reports/DishesAnulated")));
const AnulatedReport = Loadable(lazy(() => import("../views/pages/Reports/AnulatedReport")));
const UnregisteredOut = Loadable(lazy(() => import("../views/pages/Reports/UnregisteredOut")));
const CuadreCaja = Loadable(lazy(() => import("../views/pages/CuadreCaja")));
const InventarioConsumoDiario = Loadable(lazy(() => import("../views/pages/InventarioConsumoDiario")));
const Stock = Loadable(lazy(() => import("../views/pages/Stock")));
const Pendientes = Loadable(lazy(() => import("../views/pages/Pendientes")));
const Vouchers = Loadable(lazy(() => import("../views/pages/Vouchers")));
const Menu = Loadable(lazy(() => import("../views/pages/Menu")));
const MenuSubcategory = Loadable(lazy(() => import("../views/pages/Menu/MenuSubcategory")));
const MenuDishes = Loadable(lazy(() => import("../views/pages/Menu/MenuDishes")));
const MenuPromo = Loadable(lazy(() => import("../views/pages/Menu/MenuPromo")));
const Cart = Loadable(lazy(() => import("../views/pages/Menu/Cart")));
const Promo = Loadable(lazy(() => import("../views/pages/Promo")));
const ConfigMenu = Loadable(lazy(() => import("../views/pages/ConfigMenu")));
const FacturacionEmpresa = Loadable(lazy(() => import("../views/pages/FacturacionEmpresa")));
const Comprobante = Loadable(lazy(() => import("../views/pages/Comprobantes")));
const BoxConfig = Loadable(lazy(() => import("../views/pages/BoxConfig")));
const DeliveryConfig = Loadable(lazy(() => import("../views/pages/DeliveryConfig")));
const BannerConfig = Loadable(lazy(() => import("../views/pages/BannerConfig")));
const WaiterConfig = Loadable(lazy(() => import("../views/pages/WaiterConfig")));
const DeliveryOrdersList = Loadable(lazy(() => import("../views/pages/DeliveryOrdersList")));
const ControlPagos = Loadable(lazy(() => import("../views/pages/ControlPagos")));
const AccountingReport = Loadable(lazy(() => import("../views/pages/Reports/AccountingReport")));
const DailyLog = Loadable(lazy(() => import("../views/pages/Reports/DailyLog")));
const GenerateVoucher = Loadable(lazy(() => import("../views/pages/GenerateVoucher")));
const ProductExonerate = Loadable(lazy(() => import("../views/pages/Reports/ProductExonerate")));
const Claims = Loadable(lazy(() => import("../views/pages/Claims")));
const ListClaims = Loadable(lazy(() => import("../views/pages/Claims/ListClaims")));
const PaymentsAccountReport = Loadable(lazy(() => import("../views/pages/Reports/PaymentsAccount")));
const PaymentsAccountByClient = Loadable(
  lazy(() => import("../views/pages/Reports/PaymentsAccount/PayAccountByClient"))
);
const Deliverista = Loadable(lazy(() => import("../views/pages/Deliverista")));
const ReportGastos = Loadable(lazy(() => import("../views/pages/ShoppingRecord/ReportGastos")));

const VoucherById = Loadable(lazy(() => import("../views/pages/Vouchers/_id")));

const routes = [
  {
    path: "/login",
    exact: true,
    listRoles: [
      "superadmin",
      "admin",
      "supervisor",
      "cocinero",
      "mozo",
      "caja",
      "mozo_mod",
      "envios",
      "almacen",
      "contador",
    ],
    component: Login,
    isPrivate: false,
    name: "login",
  },
  {
    path: "/:url/carta",
    exact: true,
    listRoles: [
      "superadmin",
      "admin",
      "supervisor",
      "cocinero",
      "mozo",
      "caja",
      "mozo_mod",
      "envios",
      "almacen",
      "contador",
    ],
    component: Menu,
    isPrivate: false,
    name: "carta",
  },
  {
    path: "/:url/reclamos",
    exact: true,
    listRoles: [],
    component: Claims,
    isPrivate: false,
    name: "claims",
  },
  {
    path: "/:url/comprobante",
    exact: true,
    listRoles: [
      "superadmin",
      "admin",
      "supervisor",
      "cocinero",
      "mozo",
      "caja",
      "mozo_mod",
      "envios",
      "almacen",
      "contador",
    ],
    component: Comprobante,
    isPrivate: false,
    name: "comprobante-electronico",
  },
  {
    path: "/:url/carta/categorias/:idCategory",
    exact: true,
    listRoles: [
      "superadmin",
      "admin",
      "supervisor",
      "cocinero",
      "mozo",
      "caja",
      "mozo_mod",
      "envios",
      "almacen",
      "contador",
    ],
    component: MenuSubcategory,
    isPrivate: false,
    name: "carta-categoria",
  },
  {
    path: "/:url/carta/categorias/platos/:nameCategory/:idSubcategory",
    exact: true,
    listRoles: [
      "superadmin",
      "admin",
      "supervisor",
      "cocinero",
      "mozo",
      "caja",
      "mozo_mod",
      "envios",
      "almacen",
      "contador",
    ],
    component: MenuDishes,
    isPrivate: false,
    name: "carta-platos",
  },
  {
    path: "/:url/carta/promociones",
    exact: true,
    listRoles: [
      "superadmin",
      "admin",
      "supervisor",
      "cocinero",
      "mozo",
      "caja",
      "mozo_mod",
      "envios",
      "almacen",
      "contador",
    ],
    component: MenuPromo,
    isPrivate: false,
    name: "carta-platos",
  },
  {
    path: "/:url/carta/confirmar-compra",
    exact: true,
    listRoles: [
      "superadmin",
      "admin",
      "supervisor",
      "cocinero",
      "mozo",
      "caja",
      "mozo_mod",
      "envios",
      "almacen",
      "contador",
    ],
    component: Cart,
    isPrivate: false,
    name: "carta-carrito",
  },

  {
    path: "/forgotpassword",
    exact: true,
    listRoles: [
      "superadmin",
      "admin",
      "supervisor",
      "cocinero",
      "mozo",
      "caja",
      "mozo_mod",
      "envios",
      "almacen",
      "contador",
    ],
    component: RecoveryPassword,
    isPrivate: false,
    name: "recoverypassword",
  },
  {
    path: "/forgotpassword/:token",
    exact: true,
    listRoles: [
      "superadmin",
      "admin",
      "supervisor",
      "cocinero",
      "mozo",
      "caja",
      "mozo_mod",
      "envios",
      "almacen",
      "contador",
    ],
    component: Token,
    isPrivate: false,
    name: "auth",
  },
  {
    path: "/inicio",
    exact: true,
    listRoles: [
      "superadmin",
      "admin",
      "supervisor",
      "cocinero",
      "mozo",
      "caja",
      "mozo_mod",
      "envios",
      "almacen",
      "contador",
    ],
    component: Dashboard,
    isPrivate: true,
    name: "dashboard",
  },
  {
    path: "/",
    exact: true,
    component: Home,
    isPrivate: false,
    name: "home",
  },
  {
    path: "/solicitar-demo",
    exact: true,
    component: RequestDemo,
    isPrivate: false,
    name: "requestDemo",
  },
  {
    path: "/profile",
    exact: true,
    listRoles: [
      "superadmin",
      "admin",
      "supervisor",
      "cocinero",
      "mozo",
      "caja",
      "mozo_mod",
      "envios",
      "almacen",
      "contador",
    ],
    component: Profile,
    isPrivate: true,
    name: "profile",
  },
  {
    path: "/deliverista",
    exact: true,
    listRoles: ["envios", "superadmin", "admin"],
    component: Deliverista,
    isPrivate: true,
    name: "deliverista",
  },
  {
    path: "/config",
    exact: true,
    listRoles: ["superadmin", "admin"],
    component: ConfigRestaurant,
    isPrivate: true,
    name: "config",
  },
  {
    path: "/configuracion/categorias",
    exact: true,
    listRoles: ["superadmin", "admin", "supervisor"],
    component: Categories,
    isPrivate: true,
    name: "categories",
  },
  {
    path: "/configuracion/subcategorias",
    exact: true,
    listRoles: ["superadmin", "admin", "supervisor"],
    component: SubCategories,
    isPrivate: true,
    name: "categories",
  },
  {
    path: "/configuracion/platos",
    exact: true,
    listRoles: ["superadmin", "admin", "supervisor"],
    component: Dishes,
    isPrivate: true,
    name: "categories",
  },
  {
    path: "/configuracion/bebidas",
    exact: true,
    listRoles: ["superadmin", "admin", "supervisor"],
    component: Drinks,
    isPrivate: true,
    name: "categories",
  },
  {
    path: "/menu/nivel",
    exact: true,
    listRoles: ["superadmin", "admin", "supervisor"],
    component: Level,
    isPrivate: true,
    name: "level",
  },
  {
    path: "/menu/area",
    exact: true,
    listRoles: ["superadmin", "admin", "supervisor"],
    component: Area,
    isPrivate: true,
    name: "area",
  },
  {
    path: "/menu/mesas",
    exact: true,
    listRoles: ["superadmin", "admin", "supervisor"],
    component: Tables,
    isPrivate: true,
    name: "tables",
  },
  {
    path: "/clientes",
    exact: true,
    listRoles: ["superadmin", "admin", "supervisor"],
    component: Customers,
    isPrivate: true,
    name: "customers",
  },
  {
    path: "/pago-cuenta",
    exact: true,
    listRoles: ["superadmin", "admin", "supervisor", "caja", "mozo_mod"],
    component: CustomerCuenta,
    isPrivate: true,
    name: "customers",
  },
  {
    path: "/proveedores",
    exact: true,
    listRoles: ["superadmin", "admin", "supervisor", "almacen"],
    component: Providers,
    isPrivate: true,
    name: "providers",
  },
  {
    path: "/restaurantes",
    exact: true,
    listRoles: ["superadmin"],
    component: Restaurants,
    isPrivate: true,
    name: "restaurantes",
  },
  {
    path: "/restaurantes/eliminados",
    exact: true,
    listRoles: ["superadmin"],
    component: RestaurantsDelete,
    isPrivate: true,
    name: "restaurantes",
  },
  {
    path: "/configuracion/ingredientes",
    exact: true,
    listRoles: ["superadmin", "admin", "supervisor", "almacen"],
    component: Ingredients,
    isPrivate: true,
    name: "ingredients",
  },
  {
    path: "/configuracion/consumibles",
    exact: true,
    listRoles: ["superadmin", "admin", "supervisor", "almacen"],
    component: Consumables,
    isPrivate: true,
    name: "consumables",
  },
  {
    path: "/destinos/impresion",
    exact: true,
    listRoles: ["superadmin", "admin", "supervisor"],
    component: AsignDishesToPrint,
    isPrivate: true,
    name: "destinosimpresion",
  },
  {
    path: "/destinos-area/impresion",
    exact: true,
    listRoles: ["superadmin", "admin", "supervisor"],
    component: AsignAreaToPrint,
    isPrivate: true,
    name: "destinosimpresionArea",
  },
  {
    path: "/pedidos",
    exact: true,
    listRoles: ["superadmin", "admin", "mozo", "caja", "mozo_mod", "supervisor"],
    component: Orders,
    isPrivate: true,
    name: "orders",
  },
  {
    path: "/usuarios",
    exact: true,
    listRoles: ["superadmin", "admin", "supervisor"],
    component: Users,
    isPrivate: true,
    name: "users",
  },
  {
    path: "/lista/pedidos",
    exact: true,
    listRoles: [
      "superadmin",
      "admin",
      "supervisor",
      // "cocinero",
      "mozo",
      "caja",
      "mozo_mod",
    ],
    component: OrdersList,
    isPrivate: true,
    name: "ordersList",
  },
  {
    path: "/lista/cocina",
    exact: true,
    listRoles: ["superadmin", "admin", "supervisor", "cocinero"],
    component: Kitchen,
    isPrivate: true,
    name: "kitchen",
  },
  {
    path: "/caja",
    exact: true,
    listRoles: ["superadmin", "admin", "supervisor", "caja", "mozo_mod"],
    component: Cashier,
    isPrivate: true,
    name: "cashier",
  },
  {
    path: "/freetables",
    exact: true,
    listRoles: ["superadmin", "admin", "supervisor", "mozo", "caja", "mozo_mod"],
    component: FreeTable,
    isPrivate: true,
    name: "freetables",
  },
  {
    path: "/lista-espera",
    exact: true,
    listRoles: ["superadmin", "admin", "supervisor", "mozo", "caja", "mozo_mod"],
    component: WaitList,
    isPrivate: true,
    name: "freetables",
  },
  {
    path: "/importacion",
    exact: true,
    listRoles: ["superadmin", "admin"],
    component: Imports,
    isPrivate: true,
    name: "imports",
  },
  {
    path: "/impresion",
    exact: true,
    listRoles: ["superadmin", "admin", "caja", "mozo_mod"],
    component: Print,
    isPrivate: true,
    name: "print",
  },
  {
    path: "/kardex",
    exact: true,
    listRoles: ["superadmin", "admin", "supervisor"],
    component: Kardex,
    isPrivate: true,
    name: "kardex",
  },
  {
    path: "/configuracion/unidades",
    exact: true,
    listRoles: ["superadmin", "admin"],
    component: Units,
    isPrivate: true,
    name: "units",
  },
  {
    path: "/configuracion/metodo-pago",
    exact: true,
    listRoles: ["superadmin", "admin"],
    component: MethodPayment,
    isPrivate: true,
    name: "methodPay",
  },
  {
    path: "/configuracion/delivery",
    exact: true,
    listRoles: ["superadmin", "admin"],
    component: DeliveryConfig,
    isPrivate: true,
    name: "deliveryConfig",
  },
  {
    path: "/configuracion/mozo",
    exact: true,
    listRoles: ["superadmin", "admin"],
    component: WaiterConfig,
    isPrivate: true,
    name: "waiterConfig",
  },
  {
    path: "/configuracion/carta-trabajo",
    exact: true,
    listRoles: ["superadmin", "admin"],
    component: WorkersMenu,
    isPrivate: true,
    name: "workingMenu",
  },
  {
    path: "/configuracion/banner",
    exact: true,
    listRoles: ["superadmin", "admin"],
    component: BannerConfig,
    isPrivate: true,
    name: "bannerConfig",
  },
  {
    path: "/configuracion/caja",
    exact: true,
    listRoles: ["superadmin", "admin", "supervisor"],
    component: BoxConfig,
    isPrivate: true,
    name: "boxConfig",
  },
  {
    path: "/reportes",
    exact: true,
    listRoles: ["superadmin", "admin", "contador"],
    component: Reports,
    isPrivate: true,
    name: "reports",
  },
  {
    path: "/reportes/pagos-cuenta",
    exact: true,
    listRoles: ["superadmin", "admin"],
    component: PaymentsAccountReport,
    isPrivate: true,
    name: "accountPayments",
  },
  {
    path: "/reportes/pagos-cuenta-cliente",
    exact: true,
    listRoles: ["superadmin", "admin"],
    component: PaymentsAccountByClient,
    isPrivate: true,
    name: "accountPaymentsClient",
  },
  {
    path: "/reportes/pedidos",
    exact: true,
    listRoles: ["superadmin", "admin"],
    component: OrdersReport,
    isPrivate: true,
    name: "reports",
  },
  {
    path: "/reportes/anulados",
    exact: true,
    listRoles: ["superadmin", "admin"],
    component: AnulatedReport,
    isPrivate: true,
    name: "reports",
  },
  {
    path: "/reportes/eliminados",
    exact: true,
    listRoles: ["superadmin", "admin"],
    component: ProductsDelete,
    isPrivate: true,
    name: "reports",
  },
  {
    path: "/reportes/platos",
    exact: true,
    listRoles: ["superadmin", "admin"],
    component: DishesReport,
    isPrivate: true,
    name: "reports",
  },
  {
    path: "/reportes/reporte-diario",
    exact: true,
    listRoles: ["superadmin", "admin"],
    component: DailyLog,
    isPrivate: true,
    name: "reports",
  },
  {
    path: "/reportes/contabilidad",
    exact: true,
    listRoles: ["superadmin", "admin", "contador"],
    component: AccountingReport,
    isPrivate: true,
    name: "reports",
  },
  {
    path: "/reportes/delivery",
    exact: true,
    listRoles: ["superadmin", "admin"],
    component: DeliveryReport,
    isPrivate: true,
    name: "reports",
  },
  {
    path: "/reportes/mozos",
    exact: true,
    listRoles: ["superadmin", "admin"],
    component: WaitersReport,
    isPrivate: true,
    name: "reports",
  },
  {
    path: "/reportes/pagos",
    exact: true,
    listRoles: ["superadmin", "admin"],
    component: HistoryPayment,
    isPrivate: true,
    name: "historypayment",
  },
  {
    path: "/reportes/metodosDePagos",
    exact: true,
    listRoles: ["superadmin", "admin"],
    component: MethodPaymentReport,
    isPrivate: true,
    name: "methodpaymentreport",
  },
  {
    path: "/reportes/salidas-manuales",
    exact: true,
    listRoles: ["superadmin", "admin"],
    component: UnregisteredOut,
    isPrivate: true,
    name: "unregisteredreport",
  },
  {
    path: "/registro-compras",
    exact: true,
    listRoles: ["superadmin", "admin"],
    component: ShoppingRecord,
    isPrivate: true,
    name: "shoppingrecord",
  },
  {
    path: "/reporte-gastos",
    exact: true,
    listRoles: ["superadmin", "admin"],
    component: ReportGastos,
    isPrivate: true,
    name: "reportgastos",
  },
  {
    path: "/cuadre-caja",
    exact: true,
    listRoles: ["superadmin", "admin", "supervisor", "caja"],
    component: CuadreCaja,
    isPrivate: true,
    name: "cuadreCaja",
  },
  {
    path: "/inventario/movimiento-diario",
    exact: true,
    listRoles: ["superadmin", "admin", "supervisor"],
    component: InventarioConsumoDiario,
    isPrivate: true,
    name: "inventarioConsumoDiario",
  },
  {
    path: "/stock-productos",
    exact: true,
    listRoles: ["superadmin", "admin", "caja", "mozo_mod", "supervisor"],
    component: Stock,
    isPrivate: true,
    name: "cuadreInventario",
  },
  {
    path: "/facturacion/pendientes",
    exact: true,
    listRoles: ["superadmin", "admin", "caja", "mozo_mod", "contador"],
    component: Pendientes,
    isPrivate: true,
    name: "pendientes",
  },
  {
    path: "/facturacion/comprobantes-electronicos",
    exact: true,
    listRoles: ["superadmin", "admin", "caja", "mozo_mod", "contador", "supervisor"],
    component: Vouchers,
    isPrivate: true,
    name: "vouchers",
  },
  {
    path: "/facturacion/comprobantes-electronicos/:id",
    exact: true,
    listRoles: ["superadmin", "admin", "caja", "mozo_mod", "contador"],
    component: VoucherById,
    isPrivate: true,
    name: "voucherById",
  },
  {
    path: "/facturacion/generar-empresa",
    exact: true,
    listRoles: ["superadmin", "admin"],
    component: FacturacionEmpresa,
    isPrivate: true,
    name: "factEmpresa",
  },
  {
    path: "/facturacion/generar-comprobante",
    exact: true,
    listRoles: ["superadmin", "admin"],
    component: GenerateVoucher,
    isPrivate: true,
    name: "generateVoucher",
  },
  {
    path: "/reportes/boletas",
    exact: true,
    listRoles: ["superadmin", "admin", "contador"],
    component: BallotsReport,
    isPrivate: true,
    name: "reporte-boletas",
  },
  {
    path: "/reportes/facturas",
    exact: true,
    listRoles: ["superadmin", "admin", "contador"],
    component: InvoiceReport,
    isPrivate: true,
    name: "vouchers",
  },
  {
    path: "/reportes/platos-anulados",
    exact: true,
    listRoles: ["superadmin", "admin"],
    component: DishesAnulatedReport,
    isPrivate: true,
  },
  {
    path: "/reportes/notas-ventas",
    exact: true,
    listRoles: ["superadmin", "admin", "contador"],
    component: SalesNoteReport,
    isPrivate: true,
    name: "vouchers",
  },
  {
    path: "/reportes/notas-credito",
    exact: true,
    listRoles: ["superadmin", "admin", "contador"],
    component: CreditNoteReport,
    isPrivate: true,
    name: "vouchers",
  },
  {
    path: "/reportes/promociones",
    exact: true,
    listRoles: ["superadmin", "admin"],
    component: PromotionReport,
    isPrivate: true,
    name: "vouchers",
  },
  {
    path: "/reportes/general",
    exact: true,
    listRoles: ["superadmin", "admin"],
    component: GeneralReport,
    isPrivate: true,
    name: "vouchers",
  },
  {
    path: "/reportes/menu",
    exact: true,
    listRoles: ["superadmin", "admin"],
    component: MenuReport,
    isPrivate: true,
  },
  {
    path: "/reportes/exonerados",
    exact: true,
    listRoles: ["superadmin", "admin"],
    component: ProductExonerate,
    isPrivate: true,
    name: "vouchers",
  },
  {
    path: "/configuracion/menu/promociones",
    exact: true,
    listRoles: ["superadmin", "admin"],
    component: Promo,
    isPrivate: true,
    name: "promo",
  },
  {
    path: "/configuracion/menu/menu",
    exact: true,
    listRoles: ["superadmin", "admin"],
    component: ConfigMenu,
    isPrivate: true,
    name: "configMenu",
  },
  {
    path: "/delivery/lista-pedidos",
    exact: true,
    listRoles: ["superadmin", "admin", "caja", "mozo_mod", "envios"],
    component: DeliveryOrdersList,
    isPrivate: true,
    name: "deliveryOrdersList",
  },
  {
    path: "/control-pagos",
    exact: true,
    listRoles: ["superadmin"],
    component: ControlPagos,
    isPrivate: true,
    name: "controlPagos",
  },
  {
    path: "/reclamos",
    exact: true,
    listRoles: ["superadmin"],
    component: ListClaims,
    isPrivate: true,
    name: "claims",
  },
  {
    path: "",
    exact: true,
    listRoles: ["superadmin", "admin", "supervisor", "cocinero", "mozo", "caja", "mozo_mod", "envios", "almacen"],
    component: Page404,
    isPrivate: true,
    name: "",
  },
];

export default routes;
