import admin from "./admin";
import superadmin from "./superadmin";
import supervisor from "./supervisor";
import cocinero from "./cocinero";
import mozo from "./mozo";
import caja from "./caja";
import envios from "./envios";
import almacen from "./almacen";
import cajeroSinCuadre from "./cajero-sin-cuadre";
import contador from "./contador";

import user from "src/utils/userDetails";

//-----------------------|| MENU ITEMS ||-----------------------//
let itemsNavigation = {
  id: "menu",
  title: user?.data?.f_name + " " + user?.data?.l_name,
  type: "group",
  children: [],
};
let tempArray = [];

const filterFacturation = () => {
  const cajaSinFact = caja.children.filter((e) => e.title !== "SUNAT");
  const adminSinFact = admin.children.filter((e) => e.title !== "SUNAT");
  const superAdminSinFact = superadmin.children.filter((e) => e.title !== "SUNAT");
  caja.children = cajaSinFact;
  admin.children = adminSinFact;
  superadmin.children = superAdminSinFact;
};

if (user.token && !user.restaurant?.facturation) {
  filterFacturation();
}
if (user?.roles?.includes("superadmin") || user.rol === "superadmin")
  tempArray = tempArray.concat(superadmin.children);
if (user?.roles?.includes("admin")) tempArray = tempArray.concat(admin.children);
if (user?.roles?.includes("supervisor")) tempArray = tempArray.concat(supervisor.children);
if (user?.roles?.includes("caja")) tempArray = tempArray.concat(caja.children);
if (user?.roles?.includes("mozo_mod")) tempArray = tempArray.concat(cajeroSinCuadre.children);
if (user?.roles?.includes("mozo")) tempArray = tempArray.concat(mozo.children);
if (user?.roles?.includes("cocinero")) tempArray = tempArray.concat(cocinero.children);
if (user?.roles?.includes("envios")) tempArray = tempArray.concat(envios.children);
if (user?.roles?.includes("almacen")) tempArray.push(almacen.children);
if (user?.roles?.includes("contador")) tempArray = tempArray.concat(contador.children);

// switch (user && user.rol) {
//   case "superadmin":
//     itemsNavigation = superadmin;
//     break;

//   case "admin":
//     itemsNavigation = admin;
//     break;
//   case "supervisor":
//     itemsNavigation = supervisor;
//     break;
//   case "cocinero":
//     itemsNavigation = cocinero;
//     break;
//   case "mozo":
//     itemsNavigation = mozo;
//     break;
//   case "caja":
//     itemsNavigation = caja;
//     break;
//   case "envios":
//     itemsNavigation = envios;
//     break;
//   case "almacen":
//     itemsNavigation = almacen;
//     break;

//   default:
//     itemsNavigation = {
//       id: "empty",
//       type: "item",
//       title: "NOT FOUND",
//     };
//     break;
// }
tempArray.forEach((e) => {
  if (!itemsNavigation.children.find((x) => x.id === e.id)) {
    itemsNavigation.children.push(e);
  }
});

const menuItems = { items: [itemsNavigation] };
export default menuItems;
