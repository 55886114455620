import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import animation from "src/assets/json/page401.json";
import { Typography, Grid, Button } from "@material-ui/core";
import { Link } from "react-router-dom";

const Page401 = () => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: "100vh" }}
    >
      <Grid item align="center">
        <Player
          loop
          src={animation}
          style={{ maxWidth: 350 }}
          autoplay={true}
        />
        <Typography
          variant="h3"
          color="textSecondary"
          component="h1"
          style={{ fontSize: "1.25rem", fontWeight: 600 }}
        >
          NO ESTÁS AUTORIZADO
        </Typography>
        <br />
        <Link component={Button} to={{ pathname: "/" }}>
          LLÉVAME A CASA
        </Link>
      </Grid>
    </Grid>
  );
};

export default Page401;
