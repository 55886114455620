import axios from "axios";
import { api } from "src/utils/api";
import user from "src/utils/userDetails";

export const getBox = async () => {
  let res = await axios.get(`${api}/box/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const postBox = async (data) => {
  let res = await axios.post(`${api}/box/${user.restaurant._id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const putBox = async (data, id) => {
  let res = await axios.put(`${api}/box/${id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
