import axios from "axios";
import { api } from "src/utils/api";
import user from "src/utils/userDetails";

export const getRestaurants = async () => {
  let res = await axios.get(`${api}/restaurant`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getRestaurantsDeleted = async () => {
  let res = await axios.get(`${api}/restaurant/deleted`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const postRestaurants = async (data) => {
  let res = await axios.post(`${api}/restaurant`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const putRestaurants = async (data, id) => {
  let res = await axios.put(`${api}/restaurant/${id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
export const especificPutRestaurants = async (data, id, type) => {
  //type puede ser: comprobantes - colors - delivery - ubication - multiibanner
  let res = await axios.put(`${api}/restaurant/${id}/${type}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const toogleActiveRestaurant = async (data, id) => {
  //type puede ser: comprobantes - colors - delivery - ubication - multiibanner
  let res = await axios.put(`${api}/restaurant/toggle/${id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const deleteRestaurants = async (id) => {
  let res = await axios.delete(`${api}/restaurant/${id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const fontSizeRestaurant = async (data, id) => {
  let res = await axios.put(`${api}/restaurant/${id}/font-sizes`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const automaticPrintVoucher = async (data, id) => {
  let res = await axios.put(`${api}/restaurant/automatic-printer/${id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const assignRestaurants = async (data, id) => {
  let res = await axios.put(`${api}/user/assign-restaurants/${id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getMyRestaurants = async (id) => {
  let res = await axios.get(`${api}/user/restaurants/${id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const uploadCartaPdf = async (data) => {
  let res = await axios.put(`${api}/restaurant/${user.restaurant._id}/cartaPdf`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const updateOwner = async (data, restaurantId) => {
  let res = await axios.put(`${api}/restaurant/owner/${restaurantId}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
