import axios from "axios";
import { api } from "src/utils/api";
import user from "src/utils/userDetails";

export const obtainOrdersReport = async (data) => {
  let res = await axios.post(`${api}/order/report/range/${user.restaurant._id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const obtainDishesReport = async (data) => {
  let res = await axios.post(`${api}/order/report/dishes/${user.restaurant._id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const obtainWaiterReport = async (data) => {
  let res = await axios.post(`${api}/order/report/waiter/${user.restaurant._id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const obtainMethodPaymentReport = async (data) => {
  let res = await axios.post(`${api}/order/report/method-payment/${user.restaurant._id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const obtainVoucherReport = async (type, data) => {
  let res = await axios.post(`${api}/order/report/${type}/${user.restaurant._id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const obtainSalidasManuales = async (data) => {
  let res = await axios.post(`${api}/order/report/salidas-manuales/${user.restaurant._id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const obtainPedidosAnulados = async (data) => {
  let res = await axios.post(`${api}/order/report/anuladas/${user.restaurant._id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const obtainPedidosEliminados = async (data) => {
  let res = await axios.post(`${api}/order/report/eliminadas/${user.restaurant._id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const obtainReporteDiario = async (data) => {
  let res = await axios.post(`${api}/order/report/consums/${user.restaurant._id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getReportePagosCuentas = async (data, id) => {
  let res = await axios.post(`${api}/order/report/pay-account-id/${id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getReporteGastos = async (data) => {
  const res = await axios.post(`${api}/order/report/gastos/${user.restaurant._id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
