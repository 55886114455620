import axios from "axios";
import { api } from "src/utils/api";
import user from "src/utils/userDetails";

export const getFacturacion = async (type) => {
  let res = await axios.get(`${api}/order/facturation/${type}/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
export const getFacturacionPay = async (type) => {
  let res = await axios.get(`${api}/order/facturation-pay/${type}/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
export const getCreditNote = async () => {
  let res = await axios.get(`${api}/creditnote/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const sentIndividual = async (order) => {
  let res = await axios.post(
    `${api}/fact/sendUnit/${order}/${user.restaurant._id}`,
    {},
    {
      headers: { Authorization: user.token },
    }
  );
  return res.data;
};

export const postMassive = async (data) => {
  let res = await axios.post(`${api}/fact/sendMassive/${user.restaurant._id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const generateCreditNote = async (id, data) => {
  let res = await axios.post(`${api}/fact/generate-credit-note/${id}/${user.restaurant._id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const regetVouchers = async (type, lastVoucher) => {
  const res = await axios.get(`${api}/order/facturation-repay/${type}/${user.restaurant._id}/${Number(lastVoucher)}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const obtainVoucherDay = async (data) => {
  let res = await axios.post(`${api}/fact/comprobante/status`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const obtainCreditNoteDay = async (data) => {
  let res = await axios.post(`${api}/fact/nota-credito/status`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getPaidFacturation = async (type) => {
  let res = await axios.get(`${api}/fact/paid/${type}/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const registerRestaurant = async (data) => {
  let res = await axios.post(`${api}/fact/register/${user.restaurant._id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const deleteRestaurant = async () => {
  let res = await axios.delete(`${api}/fact/delete/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getRestaurantFactInfo = async () => {
  let res = await axios.get(`${api}/fact/information/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const postSerie = async (data) => {
  let res = await axios.post(`${api}/serie/${user.restaurant._id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getSerie = async () => {
  let res = await axios.get(`${api}/serie/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const sendEmailVoucher = async (data) => {
  let res = await axios.post(`${api}/fact/send-email`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getPendingFact = async () => {
  let res = await axios.get(`${api}/order/pending-fact/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getVoucherById = async (id) => {
  const res = await axios.get(`${api}/order/info/${id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

// Generar comprobante sin afectar nada
export const postGenerateVoucher = async (data) => {
  let res = await axios.post(`${api}/order/external/${user.restaurant._id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
