import styled from "@emotion/styled";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/system";
import moment from "moment";
import React, { useCallback, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import formatMoney from "src/utils/formatMoney";
import user from "src/utils/userDetails";
import numberToString from "src/utils/numberToString";
import QRCode from "qrcode.react";
import { useContext } from "react";
import { OrderContext } from "src/context";
import { getIgv, getOpGravada, getRecargoMonto } from "src/utils/requests/recargo";
import userDetails from "src/utils/userDetails";

const pageStyle = `
  @page {
    size: 80mm 270mm;
    margin: 0mm;
  }

  @media print {
    body { -webkit-print-color-adjust: exact; padding: 10px !important;  }
    
    .pagebreak {
      page-break-before: always;
      background-color: white;
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        margin: 0;
        padding: 2px;
    }
  }
`;

const PrintText = styled(Typography)`
  color: #000;
  font-size: 12px;
  font-family: "consolas";
  margin: 0;
  line-height: 1.2;
`;

const Divider = styled("div")`
  margin: 10px 0;
`;

const PrintBoxFlex = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PrintCenter = styled("div")`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PrintBluetooh = ({ row }) => {
  const { setPrintBluetooh } = useContext(OrderContext);
  const componentRef = useRef();

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    pageStyle: pageStyle,
    onAfterPrint: () => {
      setPrintBluetooh({});
    },
  });

  useEffect(() => {
    document.querySelector("#auto-click-print").click();
  }, []);

  /* const montoDesc = Number(row.amount ?? 0) - Number(row.discount ?? 0); */

  const subTotal = row.amount + Number(row.delivery ?? 0) - Number(row.discount ?? 0) + Number(row.forkCost ?? 0);
  const gravadas = getOpGravada(subTotal, row.percentIGV, row.consumCharge);
  const IGV = getIgv(subTotal, row.percentIGV, row.consumCharge ?? 0);
  const recargoMonto = getRecargoMonto(subTotal, gravadas, IGV);

  const getRamdomPharases = () => {
    let randomPhrase = Math.floor(Math.random() * user.restaurant.impresionPhrases?.length);
    return user.restaurant.impresionPhrases[randomPhrase];
  };

  // A CUENTA ó EXONERADO ó A PAGAR
  const getServTenedorACuenta = () => {
    const forkBoolean = Boolean(row.type === "INSIDE" && row.forkCost && row.forkCost > 0);
    if (forkBoolean) {
      return `${formatMoney(row.forkCost)}`;
    }
  };
  const totalExoneradoCuentaVoucher = (row) => {
    const adelantoAmount = Number(row?.adelanto?.amount ?? 0);
    if (adelantoAmount > 0) return `${formatMoney(adelantoAmount)}`;
  };

  // BOLETA ó FACTURA
  const getServTenedor = () => {
    const forkBoolean = Boolean(row.type === "INSIDE" && row.forkCost && row.forkCost > 0);
    if (forkBoolean) {
      return `${formatMoney((Number(subTotal) * Number(user.restaurant.valueForkCost)) / 100)}`;
    }
  };

  const opGravadas = () => {
    return `${formatMoney(gravadas)}`;
  };

  const nVoucher = row.voucher === "BOLETA" ? "03" : row.voucher === "FACTURA" ? "01" : "07";
  const companyRuc = userDetails.restaurant.ruc;
  const dateQr = moment(row.date).format("DD/MM/YYYY");
  const nDocCustomer = row.customer?.n_doc || "-";

  const getTipoDoc = (nDocCustomer) => {
    switch (nDocCustomer) {
      case "ruc": {
        return "6";
      }
      case "dni": {
        return "1";
      }
      case "CNT_EXT": {
        return "4";
      }
      default: {
        return "0";
      }
    }
  };

  const qrValue = `${companyRuc}|${nVoucher}|${row.serie}|${row.correlative}|${IGV.toFixed(2)}|${subTotal.toFixed(
    2
  )}|${dateQr}|${getTipoDoc(row.customer?.t_doc || "-")}|${nDocCustomer}`;

  return (
    <div>
      <div id="auto-click-print" onClick={handlePrint}></div>

      <div className="pagebreak" ref={componentRef}>
        {/* INFORMACIÓN EMRPESA */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          <img src={user.restaurant.logo} alt="" width="100%" height="100%" style={{ maxHeight: 100, maxWidth: 200 }} />
          <div>
            <PrintText textAlign="center" style={{ fontWeight: "bold", fontSize: "14px" }}>
              {user.restaurant.name}
            </PrintText>
            <PrintText textAlign="center">RUC: {user.restaurant.ruc}</PrintText>
            <PrintText textAlign="center">Tlf: {user.restaurant.phone}</PrintText>
            <PrintText textAlign="center">{user.restaurant.address}</PrintText>
          </div>
        </Box>

        <Divider />

        {/* BOLETA */}
        {row.voucher === "BOLETA" && (
          <Box>
            <PrintText textAlign="center">BOLETA DE VENTA ELECTRONICA</PrintText>
            <PrintText textAlign="center">
              {row.serie} - {row.correlative}
            </PrintText>
          </Box>
        )}

        {/* FACTURA */}
        {row.voucher === "FACTURA" && (
          <Box>
            <PrintText textAlign="center">FACTURA DE VENTA ELECTRONICA</PrintText>
            <PrintText textAlign="center">
              {row.serie} - {row.correlative}
            </PrintText>
          </Box>
        )}

        {/* VENTA EXONERADA */}
        {row.voucher === "EXONERADO" && (
          <Box>
            <PrintText textAlign="center">VENTA EXONERADA</PrintText>
            <PrintText textAlign="center">MOTIVO: {row.note ? row.note : "N/A"}</PrintText>
          </Box>
        )}

        <Divider />

        {/* FECHA */}
        {row.status === "PAGADA" ? (
          <PrintText>FECHA: {moment(row.fechaPago).format("DD/MM/YYYY hh:mm a")}</PrintText>
        ) : (
          <PrintText>
            FECHA:{" "}
            {moment(row.date).isAfter("2021-12-16", "day") ? moment(row.date).format("DD/MM/YYYY hh:mm a") : row.date}
          </PrintText>
        )}

        {/* ORDEN */}
        <PrintText>ORDEN: {row.n_order.split("-")[1]}</PrintText>

        {/* CLIENTE */}

        <Box>
          {row.customer ? (
            <>
              <PrintText>{`${row.customer.t_doc.toUpperCase() ?? "DNI"}: ${row.customer.n_doc ?? "-"}`}</PrintText>
              <PrintText>CLIENTE: {row.customer ? row.customer.fullname : "-"}</PrintText>
            </>
          ) : row?.client ? (
            <PrintText>CLIENTE: {row?.client}</PrintText>
          ) : (
            row.type !== "INSIDE" && <PrintText>CLIENTE: {row.n_order?.slice(9)}</PrintText>
          )}
        </Box>

        {row.voucher === "A CUENTA" && (
          <>
            <PrintText>CUENTA: {row.payAccount.name}</PrintText>
          </>
        )}

        {row.dishes.length === 0 && row.promo.length === 0 && row.freeDishes === 0 && (
          <PrintText>CUENTA: {row.payAccount?.name?.toUpperCase()}</PrintText>
        )}

        {/* SI ES DELIVERY */}
        {(row.type === "DELIVERY" || row.type === "DELIVERY_WEB") && (
          <>
            <PrintText>DIRECCIÓN: {row.address ?? row.customer?.direccion ?? "-"}</PrintText>
            <PrintText>TLF: {row.customer?.phone ?? "-"}</PrintText>
            {Boolean(row.deliveryUser) && (
              <PrintText>
                DELIVERY: {row.deliveryUser?.f_name ?? ""} {row.deliveryUser?.l_name ?? ""}
              </PrintText>
            )}
          </>
        )}

        {/* PRODUCTOS */}
        <PrintText>--------------------------------------</PrintText>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <PrintText>PRODUCTO</PrintText>
          <PrintText>MONTO</PrintText>
        </div>
        <PrintText>--------------------------------------</PrintText>

        {/* DETALLES */}
        {!row.printAllDetails && (
          <>
            {row.promo.length === 0 &&
            row.dishes.length === 0 &&
            row.promoDinamic?.length === 0 &&
            row.freeDishes?.length === 0 ? (
              <PrintBoxFlex>
                <PrintText>PAGO A CUENTA:</PrintText>
                <PrintText>{formatMoney(Number(row.amountPayment))}</PrintText>
              </PrintBoxFlex>
            ) : (
              <>
                {row.promo.map((el) => {
                  if (el.status && Number(el.quantity) * Number(el.price) !== 0) {
                    return (
                      <PrintBoxFlex>
                        <PrintText>
                          {String(`${el.quantity} x ${el.promotion.name.trim().toUpperCase()}`).slice(0, 25)}
                        </PrintText>
                        <PrintText>{formatMoney(Number(el.quantity * Number(el.price)))}</PrintText>
                      </PrintBoxFlex>
                    );
                  } else return null;
                })}
                {row.promoDinamic &&
                  row.promoDinamic?.map((item) => {
                    if (item.status && Number(item.quantity ?? 1) * Number(item.price) > 0) {
                      return (
                        <PrintBoxFlex>
                          <PrintText>
                            1 x
                            {item.promotion?.name
                              ? item.promotion?.name?.toUpperCase()
                              : item?.name?.trim().toUpperCase()}
                          </PrintText>
                          <PrintText>{formatMoney(Number(1) * Number(item.price))}</PrintText>
                        </PrintBoxFlex>
                      );
                    } else return null;
                  })}
                {row.dishes.map((el) => {
                  if (el.status && Number(el.quantity) * Number(el.price) > 0) {
                    return (
                      <PrintBoxFlex>
                        <PrintText>
                          {String(`${el.quantity} x ${el.dish.name.trim().toUpperCase()}`).slice(0, 25)}
                        </PrintText>
                        <PrintText>{formatMoney(Number(el.quantity * Number(el.price)))}</PrintText>
                      </PrintBoxFlex>
                    );
                  } else return null;
                })}

                {row?.freeDishes?.map((el) => {
                  if (el.status && Number(el.quantity) * Number(el.price) > 0) {
                    return (
                      <PrintBoxFlex>
                        <PrintText>
                          {String(`${el.quantity} x ${el.description.trim().toUpperCase()}`).slice(0, 25)}
                        </PrintText>
                        <PrintText>{formatMoney(Number(el.quantity * Number(el.amount)))}</PrintText>
                      </PrintBoxFlex>
                    );
                  } else return null;
                })}
              </>
            )}
          </>
        )}

        {row.printAllDetails && (
          <PrintBoxFlex>
            <PrintText>POR CONSUMO:</PrintText>
            <PrintText>{formatMoney(Number(row.amount))}</PrintText>
          </PrintBoxFlex>
        )}

        {/* DETALLES PAGO */}
        <PrintText>--------------------------------------</PrintText>
        {!row.stauts === "POR PAGAR" || row.voucher === "EXONERADO" || row.voucher === "A CUENTA" ? (
          <>
            <PrintBoxFlex>
              <PrintText>SUBTOTAL: </PrintText>
              <PrintText>{row.amount}</PrintText>
            </PrintBoxFlex>

            <PrintBoxFlex>
              <PrintText>COSTO TENEDOR: </PrintText>
              <PrintText>{getServTenedorACuenta()} </PrintText>
            </PrintBoxFlex>

            <PrintBoxFlex>
              <PrintText>ADELANTO: </PrintText>
              <PrintText>{totalExoneradoCuentaVoucher(row)} </PrintText>
            </PrintBoxFlex>

            <PrintBoxFlex>
              <PrintText>TOTAL:</PrintText>
              <PrintText>
                {row.dishes.length === 0 &&
                row.promo.length === 0 &&
                row.promoDinamic?.length === 0 &&
                row.freeDishes?.length === 0
                  ? formatMoney(Number(row.amountPayment))
                  : formatMoney(
                      Number(row.amount) -
                        Number(row.discount ?? 0) +
                        Number(row.delivery ?? 0) +
                        Number(
                          row.forkCost > 0 ? (Number(row.amount) * Number(user.restaurant.valueForkCost)) / 100 : 0
                        )
                    )}
              </PrintText>
            </PrintBoxFlex>
          </>
        ) : (
          <>
            <PrintBoxFlex>
              <PrintText>SUBTOTAL: </PrintText>
              <PrintText>{formatMoney(Number(row.amount))}</PrintText>
            </PrintBoxFlex>

            {Number(row.delivery) > 0 && (
              <PrintBoxFlex>
                <PrintText>DELIVERY: </PrintText>
                <PrintText>{formatMoney(Number(row.delivery))}</PrintText>
              </PrintBoxFlex>
            )}

            {Number(getServTenedor()) > 0 && (
              <PrintBoxFlex>
                <PrintText>SERV. TENEDOR</PrintText>
                <PrintText>{getServTenedor()}</PrintText>
              </PrintBoxFlex>
            )}
            {row.discount > 0 && (
              <>
                <PrintBoxFlex>
                  <PrintText>DESCUENTO:</PrintText>
                  <PrintText>{formatMoney(Number(row.discount))}</PrintText>
                </PrintBoxFlex>
              </>
            )}

            <PrintBoxFlex>
              <PrintText>TOTAL A PAGAR:</PrintText>
              <PrintText>
                {formatMoney(
                  Number(row.amount) -
                    Number(row.discount ?? 0) +
                    Number(row.delivery ?? 0) +
                    Number(row.forkCost > 0 ? (Number(row.amount) * Number(user.restaurant.valueForkCost)) / 100 : 0)
                )}
              </PrintText>
            </PrintBoxFlex>

            {(row.voucher === "FACTURA" || row.voucher === "BOLETA") && (
              <>
                {user.restaurant.inafecto ? (
                  <>
                    <PrintBoxFlex>
                      <PrintText>EXONERADA:</PrintText>
                      <PrintText>
                        {formatMoney(
                          Number(row.amount) -
                            Number(row.discount ?? 0) +
                            Number(row.delivery ?? 0) +
                            Number(
                              row.forkCost > 0 ? (Number(row.amount) * Number(user.restaurant.valueForkCost)) / 100 : 0
                            )
                        )}
                      </PrintText>
                    </PrintBoxFlex>
                    <PrintBoxFlex>
                      <PrintText>IGV:</PrintText>
                      <PrintText>{formatMoney(0)}</PrintText>
                    </PrintBoxFlex>
                  </>
                ) : (
                  <>
                    <PrintBoxFlex>
                      <PrintText>OP. GRAVADA:</PrintText>
                      <PrintText>{opGravadas()}</PrintText>
                    </PrintBoxFlex>
                    <PrintBoxFlex>
                      <PrintText>IGV ({row.percentIGV}%):</PrintText>
                      <PrintText>{formatMoney(Number(IGV))}</PrintText>
                    </PrintBoxFlex>
                    <PrintBoxFlex>
                      <PrintText>R.C:</PrintText>
                      <PrintText>{formatMoney(Number(recargoMonto))}</PrintText>
                    </PrintBoxFlex>
                    <PrintBoxFlex>
                      <PrintText>{row.discount && row.discount > 0 ? "SUBTOTAL A PAGAR" : "TOTAL A PAGAR"}</PrintText>
                      <PrintText>
                        {row.discount && row.discount > 0
                          ? formatMoney(row.amount)
                          : formatMoney(
                              Number(row.amount) -
                                Number(row.discount ?? 0) +
                                Number(row.delivery ?? 0) +
                                Number(
                                  row.forkCost > 0
                                    ? (Number(row.amount) * Number(user.restaurant.valueForkCost)) / 100
                                    : 0
                                )
                            )}
                      </PrintText>
                    </PrintBoxFlex>
                  </>
                )}
              </>
            )}
          </>
        )}

        {row.status === "PAGADA" && (
          <>
            {row.voucher !== "A CUENTA" && row.voucher !== "EXONERADO" && (
              <>
                {row.method_payment.map((el) => (
                  <PrintBoxFlex>
                    <PrintText>
                      {el.method === "TARJETA DE CRÉDITO"
                        ? "TARJETA DE CREDITO"
                        : el.method === "TARJETA DE DÉBITO"
                        ? "TARJETA DE DEBITO"
                        : el.method}
                    </PrintText>
                    <PrintText>{formatMoney(Number(el.amount))}</PrintText>
                  </PrintBoxFlex>
                ))}
                <PrintBoxFlex>
                  <PrintText>TOTAL PAGADO:</PrintText>
                  <PrintText>{formatMoney(row.amountPayment)}</PrintText>
                </PrintBoxFlex>
                {row.tip > 0 && (
                  <PrintBoxFlex>
                    <PrintText>PROPINA:</PrintText>
                    <PrintText>{formatMoney(Number(row.tip ?? 0))}</PrintText>
                  </PrintBoxFlex>
                )}

                <PrintBoxFlex>
                  <PrintText>VUELTO:</PrintText>
                  <PrintText>{formatMoney(Number(row.vuelto))}</PrintText>
                </PrintBoxFlex>
              </>
            )}
          </>
        )}

        {/* MONTO EN LETRAS */}
        <PrintBoxFlex>
          <PrintText>
            SON{" "}
            {numberToString(
              Number(row.amount) -
                Number(row.discount ?? 0) +
                Number(row.delivery ?? 0) +
                Number(row.forkCost > 0 ? (Number(row.amount) * Number(user.restaurant.valueForkCost)) / 100 : 0)
            )}
          </PrintText>
        </PrintBoxFlex>

        {/* CONSUMIDO DENTRO DEL LOCAL */}
        {row.type === "INSIDE" && (
          <>
            <PrintText>--------------------------------------</PrintText>
            <PrintText>{row.table?.code ?? row.customer?.fullname ?? row.client}</PrintText>
            <PrintText>
              MOZO: {row?.mozo?.f_name.toUpperCase()} {row?.mozo?.l_name.toUpperCase()}
            </PrintText>
          </>
        )}
        <PrintText>--------------------------------------</PrintText>

        {row.status === "POR PAGAR" ? (
          <>
            <PrintText>{"RAZÓN SOCIAL: ".padEnd(40, "_")}</PrintText>
            <PrintText>{"RUC: ".padEnd(40, "_")}</PrintText>
            <PrintText>{"DIRECCIÓN: ".padEnd(40, "_")}</PrintText>
            <PrintText>{"EMAIL: ".padEnd(40, "_")}</PrintText>
          </>
        ) : (
          <>
            {/* CODIGO QR */}
            {(row.voucher === "BOLETA" || row.voucher === "FACTURA") && (
              <>
                <PrintCenter>
                  <QRCode
                    bgColor="#FFFFFF"
                    fgColor="#000000"
                    level="Q"
                    style={{
                      width: "3cm",
                      height: "3cm",
                      margin: "15px 0",
                    }}
                    value={qrValue}
                  />
                </PrintCenter>

                {/* INFORMACION INAFECTO */}
                {user.restaurant.inafecto && (
                  <>
                    <PrintText>
                      {"LEYENDA: Bienes transferidos en la amazonía\n region selva para ser consumidos en la misma\n"}
                    </PrintText>
                    <PrintText>
                      {"LEYENDA: Servicios prestados en la amazonía\n region selva para ser consumidos en la misma\n"}
                    </PrintText>
                    <PrintText>--------------------------------------</PrintText>
                  </>
                )}

                <PrintText textAlign="center">
                  {`Representación impresa de la ${row.voucher?.toLowerCase()} electronica 
                    consulte en:`}
                </PrintText>
                <PrintText textAlign="center">napfood.com/{user.restaurant.url}/comprobante</PrintText>
                <PrintText>--------------------------------------</PrintText>
              </>
            )}
            {user?.restaurant?.impresionPhrases?.length > 0 && (
              <PrintCenter>
                <PrintText textAlign="center">{getRamdomPharases()}</PrintText>
              </PrintCenter>
            )}

            <PrintText textAlign="center">GRACIAS POR SU VISITA</PrintText>
            <PrintText textAlign="center">www.napfood.com</PrintText>
          </>
        )}
      </div>
    </div>
  );
};

export default PrintBluetooh;
