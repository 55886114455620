import axios from "axios";
import { api } from "src/utils/api";
import user from "src/utils/userDetails";

export const getProviders = async () => {
  let res = await axios.get(`${api}/provider/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const postProviders = async (data) => {
  let res = await axios.post(`${api}/provider`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const putProviders = async (data, id) => {
  let res = await axios.put(`${api}/provider/${id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const deleteProviders = async (id) => {
  let res = await axios.delete(`${api}/provider/${id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
