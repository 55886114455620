import axios from "axios";
import { api } from "src/utils/api";
import user from "src/utils/userDetails";

export const getUnits = async () => {
  let res = await axios.get(`${api}/units/${user.restaurant._id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const postUnits = async (data) => {
  let res = await axios.post(`${api}/units`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const putUnits = async (data, id) => {
  let res = await axios.put(`${api}/units/${id}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const deleteUnits = async (id) => {
  let res = await axios.delete(`${api}/units/${id}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
